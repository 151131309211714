import React, { useRef } from 'react';
import '../../styles/AuthorShelf.css';
import BookCards from "../../cards/BookCards";
import useAuthorBooks from '../../utils/useAuthorBooks';
import { useAuthors } from '../../contexts/AuthorContext';
import './author.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ReactSlider from '../ReactSlider/ReactSlider';
import { SwiperSlide } from "swiper/react";
var Shelf = function () {
    var shelf = useAuthors().shelf;
    var PrevRef = useRef(null);
    var NextRef = useRef(null);
    var booksByThisAuthor = useAuthorBooks(shelf);
    return (React.createElement("div", { className: "mainShelfContainer" },
        React.createElement("div", { className: "innerShelfContainer" },
            React.createElement("div", { className: "shelfContainerHeader" },
                React.createElement("h2", null, "Books Shelf"),
                React.createElement("div", { className: "carouselBtnsShelf" },
                    React.createElement("button", { className: 'slideHelfBtns prev', ref: PrevRef },
                        React.createElement(FontAwesomeIcon, { icon: faChevronLeft, size: 'sm' })),
                    React.createElement("button", { className: 'slideHelfBtns next', ref: NextRef },
                        React.createElement(FontAwesomeIcon, { icon: faChevronRight, size: 'sm' })))),
            React.createElement("div", { className: "innerBooksShelfCarousel" },
                React.createElement(ReactSlider, { PrevRef: PrevRef, NextRef: NextRef }, booksByThisAuthor.map(function (book, bookIndex) { return (React.createElement(SwiperSlide, { key: bookIndex },
                    React.createElement(BookCards, { book: book }))); }))))));
};
export default Shelf;

import { useState } from "react";
export default function useSearchState() {
    var _a = useState(""), searchText = _a[0], setSearchText = _a[1];
    var _b = useState([]), searches = _b[0], setSearches = _b[1];
    return {
        searchText: searchText,
        setSearchText: setSearchText,
        searches: searches,
        setSearches: setSearches,
    };
}

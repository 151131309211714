import React from "react";
import { Reader } from "../Reader";
import { ReaderProvider } from "../Reader/lib/providers/ReaderProvider";
var EReader = function () {
    return (React.createElement(ReaderProvider, null,
        React.createElement("div", { className: "relative h-full w-full min-h-screen p-4" },
            React.createElement("div", { className: "max-w-6xl mx-auto grid grid-cols-1 gap-4" },
                React.createElement(Reader, null)))));
};
export default EReader;

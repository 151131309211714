var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from "react";
import { getCurLocaleTime } from "../utils";
export var Colors;
(function (Colors) {
    Colors["Yellow"] = "#FFFF00";
    Colors["Coral"] = "#FF7F50";
    Colors["SkyBlue"] = "#1E90FF";
    Colors["LimeGreen"] = "#32CD32";
    Colors["Goldenrod"] = "#DAA520";
    Colors["MediumOrchid"] = "#BA55D3";
})(Colors || (Colors = {}));
export var colors = Object.entries(Colors);
export default function useAnnotationState() {
    var _a = useState(Colors.Yellow), color = _a[0], setColor = _a[1];
    var _b = useState(""), label = _b[0], setLabel = _b[1];
    var _c = useState([]), annotations = _c[0], setAnnotations = _c[1];
    var _d = useState(null), currentSelection = _d[0], setCurrentSelection = _d[1];
    var addAnnotation = function (selection) {
        var annotation = {
            selection: selection,
            label: label,
            color: color,
            time: getCurLocaleTime()
        };
        setAnnotations(__spreadArray(__spreadArray([], annotations, true), [annotation], false));
        setLabel(""),
            setCurrentSelection(null);
        setColor(Colors.Yellow);
    };
    var removeAnnotation = function (cfiRange) {
        var annotationsFilter = annotations.filter(function (annotation) { return annotation.selection.cfiRange !== cfiRange; });
        setAnnotations(annotationsFilter);
    };
    return {
        label: label,
        setLabel: setLabel,
        color: color,
        setColor: setColor,
        annotations: annotations,
        addAnnotation: addAnnotation,
        removeAnnotation: removeAnnotation,
        currentSelection: currentSelection,
        setCurrentSelection: setCurrentSelection
    };
}

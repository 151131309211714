import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
var DetailsHeader = function (_a) {
    var onFlip = _a.onFlip, onMessageTypeUpdate = _a.onMessageTypeUpdate, messageType = _a.messageType;
    var isActive = function (type) {
        return messageType === type ? 'active' : '';
    };
    return (React.createElement("div", { className: "messageDetailsHeaderTab" },
        React.createElement("div", { className: "inputOutBtnBx" },
            React.createElement("button", { className: isActive('output'), onClick: function () { return onMessageTypeUpdate('output'); } }, "AI Output"),
            React.createElement("button", { className: isActive('input'), onClick: function () { return onMessageTypeUpdate('input'); } }, "AI Input")),
        React.createElement("button", { className: "headerFlipButton", onClick: onFlip },
            React.createElement(FontAwesomeIcon, { icon: faRefresh, size: "sm", color: "gray" }))));
};
export default DetailsHeader;

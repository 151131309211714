import React from "react";
import { BookmarksStyle as defaultBookmarksStyles, } from "./style";
import { useBookmark } from "../../lib/hooks/useReaderContext";
import { BookmarkItem } from "../../lib/components/BookmarkItem";
export var Bookmarks = function (_a) {
    var _b = _a.bookmarksStyle, bookmarksStyle = _b === void 0 ? defaultBookmarksStyles : _b;
    var bookmarks = useBookmark().bookmarks;
    return (React.createElement("div", { className: "h-full overflow-hidden flex flex-col" },
        React.createElement("p", { className: "font-semibold text-lg text-center py-2" }, "Bookmarks"),
        React.createElement("div", { className: "p-4 flex-grow overflow-auto" }, bookmarks === null || bookmarks === void 0 ? void 0 :
            bookmarks.map(function (item, i) { return (React.createElement(BookmarkItem, { key: i, bookmarkItem: item })); }),
            bookmarks.length === 0 && React.createElement("div", null, "No Bookmark found"))));
};

// src/Drawer/index.tsx
import React from "react";
import { DrawerStyle as defaultDrawerStyles } from "./style";
import { TableOfContents } from "./TableOfContents";
import { Setting } from "./Setting";
import { Bookmarks } from "./Bookmarks";
import { Annotations } from "./Annotations";
import { Search } from "./Search";
import { Tabs } from "../lib/hooks/useSidebarState";
import { useSidebar } from "../lib/hooks/useReaderContext";
export var Drawer = function (_a) {
    var _b = _a.drawerStyles, drawerStyles = _b === void 0 ? defaultDrawerStyles : _b;
    var sidebar = useSidebar().sidebar;
    return (React.createElement("div", { className: "left-12 bg-gradient-to-t from-indigo-100 to-indigo-50 text-indigo-800 w-10/12 sm:w-8/12 md:w-4/12 rounded-md shadow-md p-1 text-base" },
        sidebar == Tabs.TableOfContents && React.createElement(TableOfContents, null),
        sidebar == Tabs.Bookmarks && React.createElement(Bookmarks, null),
        sidebar == Tabs.Annotations && React.createElement(Annotations, null),
        sidebar == Tabs.Search && React.createElement(Search, null),
        sidebar == Tabs.Settings && React.createElement(Setting, null)));
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faFlag, faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import { useAuthors } from '../../contexts/AuthorContext';
var UserProfile = function (_a) {
    var currentAuthorId = _a.currentAuthorId, InputMessage = _a.InputMessage;
    var authors = useAuthors().authors;
    var author = authors.find(function (a) { return a.id === currentAuthorId; });
    if (!author) {
        return null;
    }
    return (React.createElement("div", { className: "messageCardAutorDetails" },
        React.createElement("div", { className: "messageCardAuthorProfile" },
            React.createElement("div", { className: "messageCardUserProfile" },
                React.createElement("div", { className: "messageUserProfileImg" },
                    React.createElement("img", { src: "/images/authors/".concat(author.id, ".png"), alt: "" })),
                React.createElement("div", { className: "userAuthorProfile" },
                    React.createElement("h2", null, author.id),
                    React.createElement("p", null,
                        React.createElement("span", null, "Published :"),
                        " 2 days ago"),
                    React.createElement("label", { className: "profileRatingTextPercentage" }, "87% Agree"))),
            React.createElement("div", { className: "messageCardCalltoActions" },
                React.createElement("div", { className: "innerMessageCardCalltoAction" },
                    React.createElement("button", null,
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faHeart, size: "sm", color: "red" }),
                        " ",
                        React.createElement("label", null, "20 Likes"),
                        "  "),
                    React.createElement("button", null,
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faComment, size: "sm", color: "#B6CC22" }),
                        " ",
                        React.createElement("label", null, "Start Chat"),
                        "  "),
                    React.createElement("button", null,
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faFlag, size: "sm", color: "#767676" }),
                        React.createElement("label", null, " 5 Reports"),
                        "  "),
                    React.createElement("button", null,
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faStar, size: "sm", color: "#FED928" }),
                        " ",
                        React.createElement("label", null, "20 Reviews"),
                        "  ")))),
        React.createElement("div", { className: "messageCardQuestion" },
            React.createElement("div", { className: "messageInnerQuestion" },
                React.createElement("h2", null, InputMessage)))));
};
export default UserProfile;

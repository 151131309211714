import React, { useState } from 'react';
import useStreamingText from '../utils/Stream';
import { handleReadBookClick } from '../utils/handleReadBookClick';
import { useAuthors } from '../contexts/AuthorContext';
import BooksCard from '@/components/BooksCard/BooksCard';
import { useNavigate } from 'react-router-dom';
var BookCards = function (_a) {
    var _b;
    var book = _a.book;
    var navigate = useNavigate();
    var authors = useAuthors().authors;
    var _c = useState(false), flipped = _c[0], setFlipped = _c[1];
    var onCardClick = function () {
        setFlipped(!flipped);
    };
    var author = authors.find(function (author) { var _a; return (_a = author === null || author === void 0 ? void 0 : author.books) === null || _a === void 0 ? void 0 : _a.includes(book.title); });
    var onReadBookClick = function (event) {
        event.stopPropagation();
        // navigation can't be used inside non component files 
        // Create a navigation utility using the history package or pass down the navigation 
        handleReadBookClick(book.author, book.title, navigate);
    };
    var bookDescription = "Description not found";
    if (author) {
        var bookIndex = (_b = author === null || author === void 0 ? void 0 : author.books) === null || _b === void 0 ? void 0 : _b.indexOf(book.title);
        bookDescription = (author === null || author === void 0 ? void 0 : author.book_descriptions) ? author.book_descriptions[bookIndex] : "Description not found";
    }
    var shouldStartStreaming = flipped;
    var streamedDescription = useStreamingText(bookDescription, 15, shouldStartStreaming);
    return (React.createElement("div", null,
        React.createElement(BooksCard, { image: book.imagePath, title: book.title, description: streamedDescription, flipped: flipped, onCardClick: onCardClick, onReadBookClick: onReadBookClick })));
};
export default BookCards;

import React from "react";
import { ReaderContext } from "../contexts/ReaderContext";
import useAnnotationState from "../hooks/useAnnotationState";
import useBookmarkState from "../hooks/useBookmarkState";
import { useReaderState } from "../hooks/useReaderState/index";
import { useContentState } from "../hooks/useReaderState/useContentState";
import useSearch from "../hooks/useSearchState";
import useSidebar from "../hooks/useSidebarState";
export var ReaderProvider = function (_a) {
    var children = _a.children;
    var readerState = useReaderState();
    var contentState = useContentState();
    var annotationState = useAnnotationState();
    var bookmarkState = useBookmarkState();
    var searchState = useSearch();
    var sidebarState = useSidebar();
    return (React.createElement(ReaderContext.Provider, { value: {
            readerState: readerState,
            contentState: contentState,
            annotationState: annotationState,
            bookmarkState: bookmarkState,
            searchState: searchState,
            sidebarState: sidebarState,
        } }, children));
};

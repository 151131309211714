import React from 'react';
var SkeltonLoading = function (_a) {
    var isSearched = _a.isSearched;
    return (React.createElement("div", { className: 'skelton_card_source_loding', style: { height: isSearched ? '160px' : '80px' } },
        React.createElement("div", { className: "image_skelton_loading skeleton" }),
        React.createElement("div", { className: "data_skelton_loading" },
            React.createElement("div", { className: "heading_skelton_loading skeleton", style: { height: isSearched ? '14%' : '30%' } }),
            React.createElement("div", { className: "description_skelton_loading skeleton", style: { height: isSearched ? '82%' : '65%' } }))));
};
export default SkeltonLoading;

import { useEffect, useState, useRef } from "react";
var useStreamingText = function (text, speed, startStreaming) {
    var _a = useState(""), streamedText = _a[0], setStreamedText = _a[1];
    var timeoutRefs = useRef([]);
    useEffect(function () {
        // Clear all previous timeouts when text changes or component unmounts
        timeoutRefs.current.forEach(clearTimeout);
        timeoutRefs.current = [];
        setStreamedText("");
        if (startStreaming) {
            text.split('').forEach(function (char, index) {
                var timeoutId = setTimeout(function () {
                    setStreamedText(function (prev) { return prev + char; });
                }, speed * index);
                timeoutRefs.current.push(timeoutId);
            });
        }
        // Cleanup function
        return function () {
            timeoutRefs.current.forEach(clearTimeout);
        };
    }, [text, speed, startStreaming]);
    return streamedText;
};
export default useStreamingText;

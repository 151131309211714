import { EpubCFI } from "epubjs";
/**
 * Comparison of two CFI sizes
 * - -1 : CFI 1 < CFI 2
 * - 0 : CFI 1 == CFI 2
 * - 1 : CFI 1 > CFI 2
 * @param cfi_1 CFI 1
 * @param cfi_2 CFI 2
 */
export var compareCfi = function (cfi_1, cfi_2) {
    var epubcfi = new EpubCFI();
    return epubcfi.compare(cfi_1, cfi_2);
};
/**
 * Split CFI range into two starting CFI and ending CFI
 * - null : Invalid CFIRange
 * @param cfiRange CFIRange
 */
export var cfiRangeSpliter = function (cfiRange) {
    var content = cfiRange.slice(8, -1);
    var _a = content.split(","), origin = _a[0], start = _a[1], end = _a[2];
    if (!origin || !start || !end)
        return null;
    var startCfi = "epubcfi(".concat(origin + start, ")");
    var endCfi = "epubcfi(".concat(origin + end, ")");
    return { startCfi: startCfi, endCfi: endCfi };
};
/**
 * Whether the two CFI ranges nested
 * - true : Nested
 * - false : Not nested
 * - null : Invalid CFIRange
 * @param cfiRange1 First CFIRange
 * @param cfiRange2 Second CFIRange
 */
export var clashCfiRange = function (baseCfiRange, targetCfiRange) {
    var splitCfi1 = cfiRangeSpliter(baseCfiRange);
    var splitCfi2 = cfiRangeSpliter(targetCfiRange);
    if (!splitCfi1 || !splitCfi2)
        return null;
    var s1 = splitCfi1.startCfi, e1 = splitCfi1.endCfi;
    var s2 = splitCfi2.startCfi, e2 = splitCfi2.endCfi;
    if ((compareCfi(s2, s1) <= 0 && compareCfi(s1, e2) <= 0) ||
        (compareCfi(s2, e1) <= 0 && compareCfi(e1, e2) <= 0) ||
        (compareCfi(s1, s2) <= 0 && compareCfi(e2, e1) <= 0)) {
        return true;
    }
    return false;
};

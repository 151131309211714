var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { FaGripVertical } from "react-icons/fa";
import { FaGripHorizontal } from "react-icons/fa";
import { ImSpinner11 } from "react-icons/im";
import BookUpload from "./BookUpload";
import { initJuno, listDocs } from "@junobuild/core";
import NFTCard from "./NFTCard";
var BookPortal = function () {
    var _a = useState(1), limit = _a[0], setLimit = _a[1];
    var _b = useState("grid"), view = _b[0], setView = _b[1];
    var _c = useState(undefined), data = _c[0], setData = _c[1];
    var _d = useState(undefined), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var getBooks = function (from) {
        if (from === void 0) { from = null; }
        return __awaiter(void 0, void 0, void 0, function () {
            var paginate, collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        paginate = { limit: limit };
                        if (from)
                            paginate.startAfter = from;
                        return [4 /*yield*/, listDocs({
                                collection: "books",
                                filter: { paginate: paginate },
                            })];
                    case 1:
                        collection = _a.sent();
                        setCurrentPage(Number(collection.items_page) + 1);
                        setData(collection);
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    useEffect(function () {
        var init = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, initJuno({ satelliteId: "kh5oj-myaaa-aaaal-admga-cai", container: false, })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        init();
    }, []);
    useEffect(function () {
        getBooks();
    }, [limit]);
    var getTotalPages = function () { return Math.ceil(Number(data.matches_length) / limit); };
    return (React.createElement("div", { className: "relative h-full w-full min-h-screen p-4 font-sans" },
        React.createElement("div", { className: "max-w-6xl mx-auto grid grid-cols-1 gap-4" },
            React.createElement("div", { className: "col-span-3" },
                React.createElement("div", { className: "flex items-center mb-4" },
                    React.createElement("select", { onChange: function (e) { return setLimit(e.target.value); }, className: "w-44 text-sm text-gray-600 py-3 px-4 cursor-pointer border-r-8 border-r-transparent shadow-sm rounded focus:ring-0 focus:outline-0" },
                        React.createElement("option", { value: "1" }, "1 Record Per Page"),
                        React.createElement("option", { value: "3" }, "3 Records Per Page"),
                        React.createElement("option", { value: "5" }, "5 Records Per Page"),
                        React.createElement("option", { value: "10" }, "10 Records Per Page")),
                    React.createElement("div", { className: "flex gap-2 ml-auto" },
                        React.createElement(BookUpload, null),
                        React.createElement(FaGripVertical, { onClick: function () { return setView("grid"); }, className: "border w-9 h-9 p-2 flex items-center justify-center rounded cursor-pointer border-blue-500 ".concat(view == "grid"
                                ? "text-white bg-blue-500"
                                : "text-blue-500 bg-transparent") }),
                        React.createElement(FaGripHorizontal, { onClick: function () { return setView("list"); }, className: "border w-9 h-9 p-2 flex items-center justify-center rounded cursor-pointer border-blue-500 ".concat(view == "list"
                                ? "text-white bg-blue-500"
                                : "text-blue-500 bg-transparent") }))),
                loading ? (React.createElement("div", { className: "flex items-center mt-6 text-center border border-gray-800 rounded-lg h-auto py-10 bg-blue-200" },
                    React.createElement("div", { className: "flex flex-col w-full px-4 mx-auto" },
                        React.createElement("div", { className: "p-3 mx-auto text-blue-500 bg-blue-100 rounded-full " },
                            React.createElement(ImSpinner11, { className: "animate-spin" })),
                        React.createElement("h1", { className: "mt-3 text-lg font-semibold text-gray-800 " }, "Loading"),
                        React.createElement("p", { className: "mt-2 text-base text-gray-500 " }, "Please wait while we are loading your NFT's")))) : (data === null || data === void 0 ? void 0 : data.items.length) > 0 ? (React.createElement("div", { className: "transition duration-300" }, view == "grid" ? (React.createElement("div", { className: "grid md:grid-cols-4 grid-cols-2 gap-6" }, data &&
                    data.items.length > 0 &&
                    data.items.map(function (_a, index) {
                        var book = _a.data;
                        return (React.createElement(NFTCard, { bookData: book, key: index }));
                    }))) : (React.createElement("div", { className: "flex items-start mt-6 justify-center border rounded overflow-x-auto h-auto" },
                    React.createElement("table", { className: "w-full text-sm text-left text-gray-500 h-full" },
                        React.createElement("thead", { className: "text-xs text-gray-700 uppercase bg-gray-50 " },
                            React.createElement("tr", null,
                                React.createElement("th", { scope: "col", className: "py-3 px-6" }),
                                React.createElement("th", { scope: "col", className: "py-3 px-6" }, "Title"),
                                React.createElement("th", { scope: "col", className: "py-3 px-6" }, "Author"),
                                React.createElement("th", { scope: "col", className: "py-3 px-6 text-center" }, "Status"),
                                React.createElement("th", { scope: "col", className: "py-3 px-6 text-center" }, "Data"),
                                React.createElement("th", { scope: "col", className: "py-3 px-6" }, "Actions"))),
                        React.createElement("tbody", null, data &&
                            data.items.length > 0 &&
                            data.items.map(function (_a, index) {
                                var book = _a.data;
                                return (React.createElement(NFTCard, { bookData: book, view: "list", key: index }));
                            }))))))) : (React.createElement("div", { className: "flex items-center mt-6 text-center border border-gray-800 rounded-lg h-auto py-10 bg-blue-200" },
                    React.createElement("div", { className: "flex flex-col w-full px-4 mx-auto" },
                        React.createElement("div", { className: "p-3 mx-auto text-blue-500 bg-blue-100 rounded-full " },
                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: "1.5", stroke: "currentColor", className: "w-6 h-6" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" }))),
                        React.createElement("h1", { className: "mt-3 text-lg font-semibold text-gray-800 " }, "No Books found"),
                        React.createElement("p", { className: "mt-2 text-base text-gray-500 " }, "You haven't uploaded any file yet. Feel free to upload an ebook. Thank You!")))),
                data && (data === null || data === void 0 ? void 0 : data.items.length) > 0 && (React.createElement("div", { className: "mt-6 sm:flex gap-4 sm:items-center sm:justify-between " },
                    !loading && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "text-sm text-gray-500 " },
                            "Page \u00A0",
                            React.createElement("span", { className: "font-medium text-gray-700 " },
                                currentPage,
                                " of ",
                                getTotalPages())),
                        React.createElement("div", { className: "text-sm text-gray-500 " },
                            "Total \u00A0",
                            React.createElement("span", { className: "font-medium text-gray-700 " }, data.matches_length.toString()),
                            "\u00A0Records"))),
                    React.createElement("div", { className: "flex items-center ml-auto mt-4 gap-x-4 sm:mt-0" },
                        React.createElement("button", { className: "flex items-center justify-center w-1/2 px-5 py-2 text-sm capitalize transition-colors duration-200 border border-gray-300  rounded-md sm:w-auto gap-x-2  ".concat(currentPage >= getTotalPages() || loading
                                ? "text-gray-800 bg-gray-300 "
                                : "text-gray-800 bg-white hover:bg-gray-100"), onClick: function () {
                                var _a;
                                return getBooks((_a = data.items[data.items.length - 1]) === null || _a === void 0 ? void 0 : _a.key);
                            }, disabled: currentPage >= getTotalPages() || loading },
                            React.createElement("span", null, "Go To Next Page"),
                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", strokeWidth: "1.5", stroke: "currentColor", className: "w-5 h-5 rtl:-scale-x-100" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" }))))))))));
};
export default BookPortal;

import React from "react";
import { ScrollToggleStyle as defaultScrollToggleStyles, } from "./style";
import { useReader } from "../../hooks/useReaderContext";
import { CgScrollH } from "react-icons/cg";
import { CgScrollV } from "react-icons/cg";
var ScrollToggle = function (_a) {
    var _b = _a.scrollToggleStyles, scrollToggleStyles = _b === void 0 ? defaultScrollToggleStyles : _b;
    var _c = useReader(), scroll = _c.scroll, setScroll = _c.setScroll, rendition = _c.rendition;
    function toggleTheme() {
        var _a, _b;
        if (!rendition.current)
            return;
        if (scroll === "paginated") {
            (_a = rendition.current) === null || _a === void 0 ? void 0 : _a.flow("scrolled");
            setScroll("scrolled");
        }
        else {
            (_b = rendition.current) === null || _b === void 0 ? void 0 : _b.flow("paginated");
            setScroll("paginated");
        }
    }
    return (React.createElement(React.Fragment, null, scroll === "paginated" ? (React.createElement(CgScrollV, { onClick: toggleTheme, size: 30, className: "cursor-pointer p-0.5 hover:text-indigo-800 text-gray-500" })) : (React.createElement(CgScrollH, { onClick: toggleTheme, size: 30, className: "cursor-pointer p-0.5 text-indigo-800 hover:text-gray-500" }))));
};
export default ScrollToggle;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hasImage:hover section {
    background-color: rgba(5, 5, 5, 0.4);
}

.hasImage:hover button:hover {
    background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
    opacity: 0;
}

#overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
}

#overlay.draggedover p,
#overlay.draggedover i {
    opacity: 1;
}

.group:hover .group-hover\\:text-blue-800 {
    color: #2b6cb0;
}`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/pages/BookPortal/style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".hasImage:hover section {\n    background-color: rgba(5, 5, 5, 0.4);\n}\n\n.hasImage:hover button:hover {\n    background: rgba(5, 5, 5, 0.45);\n}\n\n#overlay p,\ni {\n    opacity: 0;\n}\n\n#overlay.draggedover {\n    background-color: rgba(255, 255, 255, 0.7);\n}\n\n#overlay.draggedover p,\n#overlay.draggedover i {\n    opacity: 1;\n}\n\n.group:hover .group-hover\\:text-blue-800 {\n    color: #2b6cb0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

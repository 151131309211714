// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-popup {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.filter-item:last-child {
  margin-bottom: 0;
}

.filter-item input[type="checkbox"] {
  margin-right: 0.5rem;
}

.collapsible-section {
  margin-bottom: 1rem;
}

.collapsible-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fafc;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.collapsible-header:hover {
  background-color: #e2e8f0;
}

.collapsible-content {
  margin-top: 0.5rem;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

input[type=range] {
  width: 100%;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/styles/AuthorFilter.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,UAAU;EACV,sBAAsB;EACtB,yBAAyB;EACzB,sBAAsB;EACtB,wCAAwC;EACxC,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,oBAAoB;EACpB,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB","sourcesContent":[".filter-popup {\n  position: absolute;\n  top: 110%;\n  left: 0;\n  width: 100%;\n  z-index: 1;\n  background-color: #fff;\n  border: 1px solid #e2e8f0;\n  border-radius: 0.25rem;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  padding: 0.5rem;\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.filter-item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.5rem;\n}\n\n.filter-item:last-child {\n  margin-bottom: 0;\n}\n\n.filter-item input[type=\"checkbox\"] {\n  margin-right: 0.5rem;\n}\n\n.collapsible-section {\n  margin-bottom: 1rem;\n}\n\n.collapsible-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #f7fafc;\n  padding: 0.5rem 1rem;\n  border-radius: 0.25rem;\n  cursor: pointer;\n  font-weight: 600;\n  transition: background-color 0.3s;\n}\n\n.collapsible-header:hover {\n  background-color: #e2e8f0;\n}\n\n.collapsible-content {\n  margin-top: 0.5rem;\n}\n\n\n.slider-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\ninput[type=range] {\n  width: 100%;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

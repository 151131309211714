import React, { useState, useContext } from 'react';
var SettingsContext = React.createContext(undefined);
export var useSettings = function () {
    var context = useContext(SettingsContext);
    if (!context) {
        throw new Error("useSettings must be used within a SettingsProvider");
    }
    return context;
};
export var SettingsProvider = function (_a) {
    var children = _a.children;
    var _b = useState(3), topBooksCount = _b[0], setTopBooksCount = _b[1];
    return (React.createElement(SettingsContext.Provider, { value: { topBooksCount: topBooksCount, setTopBooksCount: setTopBooksCount } }, children));
};

// TableOfContents.tsx
import React from "react";
import { TableOfContentsStyle as defaultTableOfCotentsStyles, } from "./style";
import { useReader } from "../../lib/hooks/useReaderContext";
import { TocItem } from "../../lib/components/TocItem";
export var TableOfContents = function (_a) {
    var _b = _a.tocStyles, tocStyles = _b === void 0 ? defaultTableOfCotentsStyles : _b;
    var chapters = useReader().chapters;
    return (React.createElement("div", { className: "h-full overflow-hidden flex flex-col " },
        React.createElement("p", { className: "font-semibold text-lg text-center py-2 " }, "Table Of Contents"),
        React.createElement("div", { style: tocStyles.toc, className: "p-4 flex-grow overflow-auto" }, chapters === null || chapters === void 0 ? void 0 : chapters.map(function (item, i) { return (React.createElement(TocItem, { key: i, tocItem: item })); }))));
};

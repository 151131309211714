import React from "react";
import { ThemeToggleStyle as defaultThemeToggleStyles, } from "./style";
import { useReader } from "../../hooks/useReaderContext";
import { MdDarkMode } from "react-icons/md";
import { MdOutlineDarkMode } from "react-icons/md";
var ThemeToggle = function (_a) {
    var _b = _a.themeToggleStyles, themeToggleStyles = _b === void 0 ? defaultThemeToggleStyles : _b;
    var _c = useReader(), _d = _c.userSettings, theme = _d.theme, fontSize = _d.fontSize, setUserSettings = _c.setUserSettings;
    // rendition.current?.flow("scrolled");
    // 	rendition.current?.requireManager("continuos");
    function toggleTheme() {
        if (theme === "dark") {
            setUserSettings({
                fontSize: fontSize,
                theme: "light",
            });
        }
        else {
            setUserSettings({
                fontSize: fontSize,
                theme: "dark",
            });
        }
    }
    return (React.createElement(React.Fragment, null, theme === "dark" ? (React.createElement(MdOutlineDarkMode, { onClick: toggleTheme, size: 30, className: "cursor-pointer p-0.5 text-indigo-800 hover:text-gray-500" })) : (React.createElement(MdDarkMode, { onClick: toggleTheme, size: 30, className: "cursor-pointer p-0.5 hover:text-indigo-800 text-gray-500" }))));
};
export default ThemeToggle;

import React from "react";
import { BookmarkItemStyle as defaultBookmarkItemStyles, } from "./style";
import { useReader, useSidebar } from "../../hooks/useReaderContext";
export var BookmarkItem = function (_a) {
    var bookmarkItem = _a.bookmarkItem, _b = _a.bookmarkItemStyles, bookmarkItemStyles = _b === void 0 ? defaultBookmarkItemStyles : _b;
    var rendition = useReader().rendition;
    var setSidebar = useSidebar().setSidebar;
    var handleBookmarkItemClick = function (i) {
        rendition.current && rendition.current.display(i.location.start.cfi);
        setSidebar(null);
    };
    return (React.createElement("div", { className: "py-3" },
        React.createElement("button", { onClick: function () { return handleBookmarkItemClick(bookmarkItem); }, style: bookmarkItemStyles.itemButton, className: "text-gray-500 hover:text-gray-700 px-0" }, bookmarkItem.name),
        React.createElement("p", { className: "text-indigo-500" },
            "Added on: ",
            bookmarkItem.time)));
};

import React from "react";
import { colors } from "../../lib/hooks/useAnnotationState";
import { useAnnotation } from "@/Reader/lib/hooks/useReaderContext";
import { Tooltip } from "antd";
var ColorSelector = function () {
    var _a = useAnnotation(), color = _a.color, setColor = _a.setColor;
    return (React.createElement("div", { className: "flex space-x-2 my-2" }, colors.map(function (_a) {
        var key = _a[0], value = _a[1];
        return (React.createElement(Tooltip, { key: key, title: key },
            React.createElement("div", { className: "h-6 w-6 rounded-full cursor-pointer ".concat(color === value
                    ? "ring-2 ring-offset-2 ring-gray-400"
                    : ""), style: { backgroundColor: value }, onClick: function () { return setColor(value); } })));
    })));
};
export default ColorSelector;

// break paragraph, put search string in the center and add ... at the start and end of clipped text
export var clipParagraph = function (paragraph, searchString, maxLength) {
    if (maxLength === void 0) { maxLength = 150; }
    var searchIndex = paragraph.indexOf(searchString);
    if (searchIndex === -1 || paragraph.length <= maxLength) {
        // If the search string is not found or the paragraph is already short enough
        return paragraph;
    }
    var halfMaxLength = Math.floor((maxLength - searchString.length) / 2);
    var start = Math.max(0, searchIndex - halfMaxLength);
    var end = Math.min(paragraph.length, searchIndex + searchString.length + halfMaxLength);
    // Adjust start and end to make sure total length is within maxLength
    if (end - start > maxLength) {
        if (start === 0) {
            end = maxLength;
        }
        else if (end === paragraph.length) {
            start = paragraph.length - maxLength;
        }
    }
    // Add ellipses if clipping at the start or end
    var clippedString = (start > 0 ? "..." : "") + paragraph.substring(start, end) + (end < paragraph.length ? "..." : "");
    return clippedString;
};
// recursively iterate through the node and highlight input string
export var highlightText = function (node, textToHighlight) {
    if (node.nodeType === 3) { // Node.TEXT_NODE
        var nodeText = node.nodeValue;
        var highlightedHTML = nodeText.replace(new RegExp(textToHighlight, 'gi'), "<mark>$&</mark>");
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = highlightedHTML;
        while (tempDiv.firstChild) {
            node.parentNode.insertBefore(tempDiv.firstChild, node);
        }
        node.parentNode.removeChild(node);
    }
    else if (node.nodeType === 1) { // Node.ELEMENT_NODE
        Array.from(node.childNodes).forEach(function (n) { return highlightText(n, textToHighlight); });
    }
};

// src/Sidebar/index.tsx
import React, { useEffect, useState } from "react";
import { SidebarStyle as defaultSidebarStyles, } from "./style";
import { Tabs } from "../lib/hooks/useSidebarState";
import { useSidebar } from "../lib/hooks/useReaderContext";
import { CiMenuFries } from "react-icons/ci";
import { MdMenuBook } from "react-icons/md";
import { MdOutlineBookmarks } from "react-icons/md";
import { HiOutlineAnnotation } from "react-icons/hi";
import { MdOutlineSearch } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { SidebarItem } from "../lib/components/SidebarItem";
import { Drawer } from "../Drawer";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
export var Sidebar = function (_a) {
    var _b = _a.sidebarStyles, sidebarStyles = _b === void 0 ? defaultSidebarStyles : _b;
    var _c = useState(false), visible = _c[0], setVisible = _c[1];
    var _d = useSidebar(), sidebar = _d.sidebar, setSidebar = _d.setSidebar;
    var handleSidebarClick = function (tab) {
        if (sidebar === tab) {
            setSidebar(null);
        }
        else {
            setSidebar(tab);
        }
    };
    var handleMouseEnter = function () {
        setVisible(true);
    };
    var handleMouseLeave = function () {
        if (!sidebar)
            setVisible(false);
    };
    useEffect(function () {
        if (!sidebar)
            setVisible(false);
    }, [sidebar]);
    return (React.createElement("div", { className: "absolute inset-0 left-0 font-sans" },
        sidebar && React.createElement("div", { onClick: function () { return setSidebar(null); }, className: "cursor-pointer absolute inset-0 bg-black opacity-50 z-10" }),
        React.createElement("div", { className: "flex h-full items-stretch p-1" },
            React.createElement("div", { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, className: "z-10 h-fit flex flex-col shadow-sm items-center justify-start bg-indigo-50 border border-solid rounded" },
                React.createElement(SidebarItem, { icon: React.createElement(React.Fragment, null,
                        React.createElement(CiMenuFries, { size: 25, onClick: function () { return setVisible(true); }, className: "z-20 cursor-pointer text-gray-500 transition-all duration-300 ".concat(visible ? 'opacity-0 invisible h-0' : 'opacity-100 visible h-auto') }),
                        React.createElement(AiOutlineClose, { size: 25, onClick: function () { return setVisible(false); }, className: "z-20 cursor-pointer text-gray-500 transition-all duration-300 ".concat(!visible ? 'opacity-0 invisible h-0' : 'opacity-100 visible h-auto') })), active: false }),
                React.createElement("div", { className: "flex flex-col justify-between items-center gap-2 transition-all duration-300 ease-in-out origin-top transform ".concat(visible ? 'translate-y-0 opacity-100 visible max-h-56' : '-translate-y-10 opacity-0 invisible max-h-0') },
                    React.createElement(SidebarItem, { icon: React.createElement(MdMenuBook, { size: 25, onClick: function () {
                                return handleSidebarClick(Tabs.TableOfContents);
                            } }), active: sidebar === Tabs.TableOfContents ? true : false, tooltip: "Table Of Contents" }),
                    React.createElement(SidebarItem, { icon: React.createElement(MdOutlineBookmarks, { size: 25, onClick: function () {
                                return handleSidebarClick(Tabs.Bookmarks);
                            } }), active: sidebar === Tabs.Bookmarks ? true : false, tooltip: "Bookmarks" }),
                    React.createElement(SidebarItem, { icon: React.createElement(HiOutlineAnnotation, { size: 25, onClick: function () {
                                return handleSidebarClick(Tabs.Annotations);
                            } }), active: sidebar === Tabs.Annotations ? true : false, tooltip: "Annotations" }),
                    React.createElement(SidebarItem, { icon: React.createElement(MdOutlineSearch, { size: 25, onClick: function () { return handleSidebarClick(Tabs.Search); } }), active: sidebar === Tabs.Search ? true : false, tooltip: "Search" }),
                    React.createElement(SidebarItem, { icon: React.createElement(MdOutlineSettings, { size: 25, onClick: function () {
                                return handleSidebarClick(Tabs.Settings);
                            } }), active: sidebar === Tabs.Settings ? true : false, tooltip: "Settings" }))),
            sidebar &&
                React.createElement("div", { className: "relative flex-grow flex z-20 px-1" },
                    React.createElement(Drawer, null),
                    React.createElement("div", { className: "flex-grow flex justify-end cursor-pointer", onClick: function () { return setSidebar(null); } },
                        React.createElement(IoCloseCircleOutline, { size: 40, onClick: function () { return setSidebar(null); }, className: "md:m-3 sm:m-1 text-gray-100 hover:text-gray-300 sm:hidden" }))))));
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainShelfContainer {
  width: 98%;
  margin: 0px auto;
  background: #fff;
  height: 380px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.innerShelfContainer {
  width: 100%;
  height: 100%;
}

.shelfContainerHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(220, 220, 220);
}

.shelfContainerHeader h2 {
  font-size: 1.5rem;
  color: gray;
  font-weight: 600;
}

.carouselBtnsShelf {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.slideHelfBtns {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  cursor: pointer;
  margin-left: 15px;
  transition: 0.2s linear;
}

.slideHelfBtns:hover {
  background: #000;
  color: #fff;
  transition: 0.2s linear;
  border-color: #000;
}

.innerBooksShelfCarousel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80%;
}

.Swipper {
  padding: 15px 20px !important;
}
`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/components/Author/author.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qCAAqC;EACrC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".mainShelfContainer {\n  width: 98%;\n  margin: 0px auto;\n  background: #fff;\n  height: 380px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);\n}\n\n.innerShelfContainer {\n  width: 100%;\n  height: 100%;\n}\n\n.shelfContainerHeader {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px 20px;\n  border-bottom: 1px solid rgba(220, 220, 220);\n}\n\n.shelfContainerHeader h2 {\n  font-size: 1.5rem;\n  color: gray;\n  font-weight: 600;\n}\n\n.carouselBtnsShelf {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.slideHelfBtns {\n  height: 45px;\n  width: 45px;\n  border-radius: 50%;\n  border: 1px solid rgba(220, 220, 220);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: gray;\n  cursor: pointer;\n  margin-left: 15px;\n  transition: 0.2s linear;\n}\n\n.slideHelfBtns:hover {\n  background: #000;\n  color: #fff;\n  transition: 0.2s linear;\n  border-color: #000;\n}\n\n.innerBooksShelfCarousel {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  height: 80%;\n}\n\n.Swipper {\n  padding: 15px 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

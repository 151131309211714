import { useState, useEffect } from "react";
import { useAuthors } from "../contexts/AuthorContext";
import { sanitizeTitleForPath } from "./handleReadBookClick";
var GetOneBook = function (authorId, bookName) {
    var _a = useState([]), books = _a[0], setBooks = _a[1];
    var authors = useAuthors().authors;
    useEffect(function () {
        var _a;
        var authorInfo = authors.find(function (info) { return info.id === authorId; });
        var booksForAuthor = [];
        (_a = authorInfo === null || authorInfo === void 0 ? void 0 : authorInfo.books) === null || _a === void 0 ? void 0 : _a.forEach(function (title, index) {
            var _a;
            (bookName === null || bookName === void 0 ? void 0 : bookName.includes(title)) && booksForAuthor.push({
                author: authorId,
                description: ((_a = authorInfo.book_descriptions) === null || _a === void 0 ? void 0 : _a[index]) || "Description not available",
                categories: authorInfo.category || [],
                imagePath: "/images/books/".concat(authorId, "/").concat(sanitizeTitleForPath(title), ".png"),
                title: title,
            });
        });
        setBooks(booksForAuthor);
    }, [authorId, authors]);
    return books[0];
};
export default GetOneBook;

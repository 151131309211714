// OG: Now makes author object from pure AUTHOR_INFO
import React, { createContext, useContext, useState } from 'react';
import { AUTHOR_INFO } from '../data/author_data';
var AuthorContext = createContext(undefined);
export var AuthorProvider = function (_a) {
    var children = _a.children;
    var authors = AUTHOR_INFO;
    // stats contains author id or null 
    var _b = useState(null), stats = _b[0], setStats = _b[1];
    // shelf contains author id or null
    var _c = useState(null), shelf = _c[0], setShelf = _c[1];
    var _d = useState(null), book = _d[0], setBook = _d[1];
    return (React.createElement(AuthorContext.Provider, { value: { authors: authors, stats: stats, setStats: setStats, shelf: shelf, setShelf: setShelf, book: book, setBook: setBook } }, children));
};
export var useAuthors = function () {
    var context = useContext(AuthorContext);
    if (!context) {
        throw new Error('useAuthors must be used within an AuthorProvider');
    }
    return context;
};
export var getBooksByAuthorId = function (authorId, authors) {
    var _a;
    var author = authors.find(function (author) { return author.id === authorId; });
    if (author && ((_a = author === null || author === void 0 ? void 0 : author.books) === null || _a === void 0 ? void 0 : _a.length)) {
        return author.books.slice(0, 3);
    }
    return [];
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// TableOfContents.tsx
import React, { useEffect } from "react";
import { SearchStyle as defaultSearchStyles } from "./style";
import { useContent, useReader, useSearch } from "../../lib/hooks/useReaderContext";
import { MdOutlineSearch } from "react-icons/md";
import { SearchItem } from "../../lib/components/SearchItem";
import { EpubCFI } from "epubjs";
export var Search = function (_a) {
    var _b = _a.searchStyle, searchStyle = _b === void 0 ? defaultSearchStyles : _b;
    var book = useReader().book;
    var _c = useContent(), setContents = _c.setContents, searchContents = _c.searchContents;
    var _d = useSearch(), searchText = _d.searchText, setSearchText = _d.setSearchText, searches = _d.searches, setSearches = _d.setSearches;
    var onSearchBookContents = function () { return __awaiter(void 0, void 0, void 0, function () {
        var matches;
        return __generator(this, function (_a) {
            matches = searchContents(searchText);
            setSearches(matches);
            return [2 /*return*/];
        });
    }); };
    var handleKeyPress = function (e) {
        var key = e.key;
        if (key === "Enter") {
            onSearchBookContents();
        }
    };
    useEffect(function () {
        if (book) {
            // const loadContents = async () => {
            // 	// book.spine should be accessible as we already await for book.ready in useReader
            // 	book.loaded?.spine.then(async (spine) => {
            // 		const contents: ContentList = [];
            // 		console.log('spine',spine);
            // 		for (let item of (spine as any).items) {
            // 			if (!item.href) continue;
            // 			console.log('item',item);
            // 			const doc = await book.load(item.href);
            // 			console.log('doc',doc);
            // 			const innerHTML = (doc as Document).documentElement
            // 				.innerHTML;
            // 			const innerText = convert(innerHTML);
            // 			contents.push({
            // 				href: item.href,
            // 				text: innerText.split(/\n+/),
            // 			});
            // 		}
            // 		setContents(contents);
            // 	});
            // };
            // loadContents();
            var processBook = function () { return __awaiter(void 0, void 0, void 0, function () {
                var spine, contents, _loop_1, _i, _a, item, state_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, book.ready];
                        case 1:
                            _b.sent(); // Ensure the book is ready
                            spine = book.spine;
                            contents = [];
                            _loop_1 = function (item) {
                                var doc, innerHTML, parsedDoc, paragraphs;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            if (!item.href)
                                                return [2 /*return*/, { value: void 0 }];
                                            return [4 /*yield*/, book.load(item.href)];
                                        case 1:
                                            doc = _c.sent();
                                            innerHTML = doc.documentElement.innerHTML;
                                            parsedDoc = new DOMParser().parseFromString(innerHTML, "text/html");
                                            paragraphs = parsedDoc.querySelectorAll("p");
                                            paragraphs.forEach(function (paragraph) {
                                                var _a, _b;
                                                var text = (_b = (_a = paragraph.textContent) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : "";
                                                if (text.length < 1)
                                                    return;
                                                var cfi = new EpubCFI(paragraph, item.cfiBase);
                                                var content = {
                                                    cfi: cfi,
                                                    text: text
                                                };
                                                contents.push(content);
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            };
                            _i = 0, _a = spine.items;
                            _b.label = 2;
                        case 2:
                            if (!(_i < _a.length)) return [3 /*break*/, 5];
                            item = _a[_i];
                            return [5 /*yield**/, _loop_1(item)];
                        case 3:
                            state_1 = _b.sent();
                            if (typeof state_1 === "object")
                                return [2 /*return*/, state_1.value];
                            _b.label = 4;
                        case 4:
                            _i++;
                            return [3 /*break*/, 2];
                        case 5:
                            setContents(contents);
                            return [2 /*return*/];
                    }
                });
            }); };
            processBook();
        }
    }, [book, setContents]);
    return (React.createElement("div", { className: "h-full overflow-hidden flex flex-col" },
        React.createElement("p", { className: "font-semibold text-lg text-center py-2" }, "Search Content"),
        React.createElement("div", { className: "px-4 py-2" },
            React.createElement("div", { className: "pb-2 relative" },
                React.createElement("input", { type: "text", placeholder: "Type here", onChange: function (e) { return setSearchText(e.target.value); }, onKeyPress: handleKeyPress, className: "border-2 border-gray-300 focus:border-gray-500 outline-none w-full p-2 pr-12 rounded-md " }),
                React.createElement(MdOutlineSearch, { size: 30, onClick: onSearchBookContents, className: "absolute right-2 top-2 cursor-pointer text-gray-500 hover:text-gray-700" })),
            React.createElement("p", null,
                "Result: Total ",
                searches.length,
                " Record")),
        React.createElement("div", { className: "p-4 flex-grow overflow-auto" }, searches === null || searches === void 0 ? void 0 :
            searches.map(function (item, i) { return (React.createElement(SearchItem, { key: i, item: item })); }),
            searches.length === 0 && React.createElement("div", null, "Search Something"))));
};

// useReaderState.tsx
import { useState } from "react";
export var useContentState = function () {
    var _a = useState([]), contents = _a[0], setContents = _a[1];
    var searchContents = function (searchString) {
        var regexp = new RegExp(searchString, "ig");
        var res = [];
        for (var _i = 0, contents_1 = contents; _i < contents_1.length; _i++) {
            var content = contents_1[_i];
            if (content.text.match(regexp) !== null) {
                var searchItem = {
                    text: content.text,
                    cfi: content.cfi,
                };
                res.push(searchItem);
            }
        }
        return res;
    };
    return {
        contents: contents,
        setContents: setContents,
        searchContents: searchContents,
    };
};

import { useState } from "react";
export var Tabs;
(function (Tabs) {
    Tabs["TableOfContents"] = "toc";
    Tabs["Bookmarks"] = "bookmark";
    Tabs["Annotations"] = "annotation";
    Tabs["Search"] = "search";
    Tabs["Settings"] = "setting";
})(Tabs || (Tabs = {}));
export default function useSidebarState() {
    var _a = useState(null), sidebar = _a[0], setSidebar = _a[1];
    return {
        sidebar: sidebar,
        setSidebar: setSidebar,
    };
}

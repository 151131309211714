import React from "react";
import { AnnotationItemStyle as defaultAnnotationItemStyles, } from "./style";
import { useAnnotation, useReader, useSidebar, } from "../../hooks/useReaderContext";
import { PiTrashLight } from "react-icons/pi";
export var AnnotationItem = function (_a) {
    var annotationItem = _a.annotationItem, _b = _a.annotationItemStyles, annotationItemStyles = _b === void 0 ? defaultAnnotationItemStyles : _b;
    var rendition = useReader().rendition;
    var removeAnnotation = useAnnotation().removeAnnotation;
    var setSidebar = useSidebar().setSidebar;
    var handleAnnotationItemClick = function (i) {
        rendition.current && rendition.current.display(i.selection.cfiRange);
        setSidebar(null);
    };
    var handleRemoveAnnotationItemClick = function (i) {
        rendition.current &&
            rendition.current.annotations.remove(i.selection.cfiRange, "highlight");
        removeAnnotation(i.selection.cfiRange);
    };
    return (React.createElement("div", { className: "relative py-3 flex  justify-between items-start" },
        React.createElement("div", { className: "" },
            React.createElement("button", { onClick: function () { return handleAnnotationItemClick(annotationItem); }, style: annotationItemStyles.itemButton, className: "text-gray-500 hover:text-gray-700 px-0" }, annotationItem.label ? annotationItem.label : annotationItem.selection.text),
            React.createElement("p", { className: "text-indigo-500" },
                "Added On: ",
                annotationItem.time)),
        React.createElement("div", { className: "self-start " },
            React.createElement(PiTrashLight, { size: 30, onClick: function () {
                    return handleRemoveAnnotationItemClick(annotationItem);
                }, className: " cursor-pointer text-red-500 hover:text-red-400" }))));
};

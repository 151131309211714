// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.innnerHeaderContainer {
  border-radius: 50px !important;
}

.mainHeaderTabsContainer .item {
  padding: 12px 20px;
  font-size: 1.3rem !important;
  text-transform: uppercase;
}

.authTab {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.innerAuthTab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to right,
    rgb(127, 0, 255),
    rgb(72, 61, 139),
    rgb(70, 130, 180),
    rgb(32, 178, 170),
    rgb(163, 190, 140),
    rgb(208, 135, 112)
  );
  border-radius: 30px;
  height: 100%;
  display: inline-block;
  overflow: hidden;
}

.innerAuthTab label {
  padding: 10px 40px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

.innerAuthTab label.border-right {
  border-right: 2px solid #fff;
}
`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/styles/headerTabs.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,4BAA4B;EAC5B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB;;;;;;;;GAQC;EACD,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".innnerHeaderContainer {\n  border-radius: 50px !important;\n}\n\n.mainHeaderTabsContainer .item {\n  padding: 12px 20px;\n  font-size: 1.3rem !important;\n  text-transform: uppercase;\n}\n\n.authTab {\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 15px;\n}\n\n.innerAuthTab {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  background: linear-gradient(\n    to right,\n    rgb(127, 0, 255),\n    rgb(72, 61, 139),\n    rgb(70, 130, 180),\n    rgb(32, 178, 170),\n    rgb(163, 190, 140),\n    rgb(208, 135, 112)\n  );\n  border-radius: 30px;\n  height: 100%;\n  display: inline-block;\n  overflow: hidden;\n}\n\n.innerAuthTab label {\n  padding: 10px 40px;\n  color: #fff;\n  font-size: 1.2rem;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.innerAuthTab label.border-right {\n  border-right: 2px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { createContext, useState } from 'react';
;
export var BookMarkedSourceCardContext = createContext(undefined);
export var BookMarkedSourceCardProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), bookmarkedSourceCards = _b[0], setBookmarkedSourceCards = _b[1];
    var SetBookmarkedSourceCards = function (newSourceCards) {
        setBookmarkedSourceCards(newSourceCards);
    };
    var UpdateBookmarkedSourceCards = function (newSourceCard) {
        setBookmarkedSourceCards(function (prev) { return (__spreadArray(__spreadArray([], prev, true), [newSourceCard], false)); });
    };
    return (React.createElement(BookMarkedSourceCardContext.Provider, { value: { bookmarkedSourceCards: bookmarkedSourceCards, SetBookmarkedSourceCards: SetBookmarkedSourceCards, UpdateBookmarkedSourceCards: UpdateBookmarkedSourceCards } }, children));
};

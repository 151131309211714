// useReaderContext.tsx
import { useContext } from "react";
import { ReaderContext } from "../contexts/ReaderContext";
export var useReader = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useReader should be called inside ReaderContext");
    }
    return context.readerState;
};
export var useContent = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useContent should be called inside ReaderContext");
    }
    return context.contentState;
};
export var useAnnotation = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useAnnotation should be called inside ReaderContext");
    }
    return context.annotationState;
};
export var useBookmark = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useBookmark should be called inside ReaderContext");
    }
    return context.bookmarkState;
};
export var useSearch = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useSearch should be called inside ReaderContext");
    }
    return context.searchState;
};
export var useSidebar = function () {
    var context = useContext(ReaderContext);
    if (!context) {
        throw new Error("useSidebar should be called inside ReaderContext");
    }
    return context.sidebarState;
};

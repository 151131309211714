// src/Toolbar/index.tsx
import React, { useState } from "react";
import { ToolbarStyle as defaultToolbarStyles, } from "./style";
import BookmarkToggle from "../lib/components/BookmarkToggle";
import FullScreenToggle from "../lib/components/FullScreenToggle";
import ThemeToggle from "../lib/components/ThemeToggle";
import ScrollToggle from "../lib/components/ScrollToggle";
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
export var Toolbar = function (_a) {
    var _b = _a.toolbarStyles, toolbarStyles = _b === void 0 ? defaultToolbarStyles : _b;
    var _c = useState(false), visible = _c[0], setVisible = _c[1];
    return (React.createElement("div", { onMouseEnter: function () { return setVisible(true); }, onMouseLeave: function () { return setVisible(false); }, className: "absolute z-10 flex flex-col justify-center items-center right-1 top-1 w-fit bg-indigo-50 h-fit border border-solid rounded-full" },
        React.createElement(FiChevronsDown, { size: 30, onClick: function () { return setVisible(true); }, className: "cursor-pointer text-gray-500 transition-all duration-300 ".concat(visible ? 'opacity-0 invisible h-0' : 'opacity-100 visible h-auto') }),
        React.createElement(FiChevronsUp, { size: 30, onClick: function () { return setVisible(false); }, className: "cursor-pointer text-gray-500 transition-all duration-300 ".concat(!visible ? 'opacity-0 invisible h-0' : 'opacity-100 visible h-auto pb-2') }),
        React.createElement("div", { className: "flex flex-col justify-between items-center gap-2 transition-all duration-300 ease-in-out origin-top transform ".concat(visible ? 'translate-y-0 opacity-100 visible max-h-36' : '-translate-y-10 opacity-0 invisible max-h-0') },
            React.createElement(BookmarkToggle, null),
            React.createElement(FullScreenToggle, null),
            React.createElement(ThemeToggle, null),
            React.createElement(ScrollToggle, null))));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @ts-nocheck
import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';
import { Button, Icon, Toolbar } from '../dashboards/textbox';
var HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};
var LIST_TYPES = ['numbered-list', 'bulleted-list'];
var TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
var Post = function () {
    var renderElement = useCallback(function (props) { return React.createElement(Element, __assign({}, props)); }, []);
    var renderLeaf = useCallback(function (props) { return React.createElement(Leaf, __assign({}, props)); }, []);
    var editor = useMemo(function () { return withHistory(withReact(createEditor())); }, []);
    return (React.createElement("div", { style: {
            maxWidth: '700px',
            width: 'calc(100% - 40px)',
            padding: '40px 20px',
            margin: '0 auto'
        } },
        React.createElement(Slate, { editor: editor, initialValue: initialValue },
            React.createElement(Toolbar, null,
                React.createElement(MarkButton, { format: "bold", icon: "format_bold" }),
                React.createElement(MarkButton, { format: "italic", icon: "format_italic" }),
                React.createElement(MarkButton, { format: "underline", icon: "format_underlined" }),
                React.createElement(MarkButton, { format: "code", icon: "code" }),
                React.createElement(BlockButton, { format: "heading-one", icon: "looks_one" }),
                React.createElement(BlockButton, { format: "heading-two", icon: "looks_two" }),
                React.createElement(BlockButton, { format: "block-quote", icon: "format_quote" }),
                React.createElement(BlockButton, { format: "numbered-list", icon: "format_list_numbered" }),
                React.createElement(BlockButton, { format: "bulleted-list", icon: "format_list_bulleted" }),
                React.createElement(BlockButton, { format: "left", icon: "format_align_left" }),
                React.createElement(BlockButton, { format: "center", icon: "format_align_center" }),
                React.createElement(BlockButton, { format: "right", icon: "format_align_right" }),
                React.createElement(BlockButton, { format: "justify", icon: "format_align_justify" })),
            React.createElement(Editable, { renderElement: renderElement, renderLeaf: renderLeaf, placeholder: "Enter some rich text\u2026", spellCheck: true, autoFocus: true, onKeyDown: function (event) {
                    for (var hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event)) {
                            event.preventDefault();
                            var mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    }
                } }))));
};
var toggleBlock = function (editor, format) {
    var isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    var isList = LIST_TYPES.includes(format);
    Transforms.unwrapNodes(editor, {
        match: function (n) {
            return !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format);
        },
        split: true,
    });
    var newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        };
    }
    else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        };
    }
    Transforms.setNodes(editor, newProperties);
    if (!isActive && isList) {
        var block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};
var toggleMark = function (editor, format) {
    var isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    }
    else {
        Editor.addMark(editor, format, true);
    }
};
var isBlockActive = function (editor, format, blockType) {
    if (blockType === void 0) { blockType = 'type'; }
    var selection = editor.selection;
    if (!selection)
        return false;
    var match = Array.from(Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: function (n) {
            return !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format;
        },
    }))[0];
    return !!match;
};
var isMarkActive = function (editor, format) {
    var marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};
var Element = function (_a) {
    var attributes = _a.attributes, children = _a.children, element = _a.element;
    var style = { textAlign: element.align };
    switch (element.type) {
        case 'block-quote':
            return (React.createElement("blockquote", __assign({ style: style }, attributes), children));
        case 'bulleted-list':
            return (React.createElement("ul", __assign({ style: style }, attributes), children));
        case 'heading-one':
            return (React.createElement("h1", __assign({ style: style }, attributes), children));
        case 'heading-two':
            return (React.createElement("h2", __assign({ style: style }, attributes), children));
        case 'list-item':
            return (React.createElement("li", __assign({ style: style }, attributes), children));
        case 'numbered-list':
            return (React.createElement("ol", __assign({ style: style }, attributes), children));
        default:
            return (React.createElement("p", __assign({ style: style }, attributes), children));
    }
};
var Leaf = function (_a) {
    var attributes = _a.attributes, children = _a.children, leaf = _a.leaf;
    if (leaf.bold) {
        children = React.createElement("strong", null, children);
    }
    if (leaf.code) {
        children = React.createElement("code", null, children);
    }
    if (leaf.italic) {
        children = React.createElement("em", null, children);
    }
    if (leaf.underline) {
        children = React.createElement("u", null, children);
    }
    return React.createElement("span", __assign({}, attributes), children);
};
var BlockButton = function (_a) {
    var format = _a.format, icon = _a.icon;
    var editor = useSlate();
    return (React.createElement(Button, { active: isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'), onMouseDown: function (event) {
            event.preventDefault();
            toggleBlock(editor, format);
        } },
        React.createElement(Icon, null, icon)));
};
var MarkButton = function (_a) {
    var format = _a.format, icon = _a.icon;
    var editor = useSlate();
    return (React.createElement(Button, { active: isMarkActive(editor, format), onMouseDown: function (event) {
            event.preventDefault();
            toggleMark(editor, format);
        } },
        React.createElement(Icon, null, icon)));
};
var initialValue = [
    {
        type: 'paragraph',
        children: [
            { text: 'This is editable ' },
            { text: 'rich', bold: true },
            { text: ' text, ' },
            { text: 'much', italic: true },
            { text: ' better than a ' },
            { text: '<textarea>', code: true },
            { text: '!' },
        ],
    },
    {
        type: 'paragraph',
        children: [
            {
                text: "Since it's rich text, you can do things like turn a selection of text ",
            },
            { text: 'bold', bold: true },
            {
                text: ', or add a semantically rendered block quote in the middle of the page, like this:',
            },
        ],
    },
    {
        type: 'block-quote',
        children: [{ text: 'A wise quote.' }],
    },
    {
        type: 'paragraph',
        align: 'center',
        children: [{ text: 'Try it out for yourself!' }],
    },
];
export default Post;

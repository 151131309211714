// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_bookkmarkedSourceCards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerBookmarked_header {
  width: 95%;
  padding: 20px;
  background: #fff;
  margin: 0px auto;
  border: 2px solid rgba(245, 245, 245);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innerBookmarked_header h2 {
  font-size: 1.8rem;
  color: #000;
  font-weight: 600;
}

.innerBookmarked_header button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
}

.bookmarkedCardsInnerContainer {
  width: 95%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s linear;
}

.bookmarkedCardsInnerContainer.active {
  transition: 0.2s linear;
  overflow: unset;
  max-height: 5000px;
}

.bookmarkedCardsInnerContainer .searchedCardBx {
  background: #fff;
}

.bookmarkedCardsInnerContainer .innerSearchedTextData h2 {
  max-width: 900px;
}
`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/styles/sourcecardspanel.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,qCAAqC;EACrC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".header_bookkmarkedSourceCards {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.innerBookmarked_header {\n  width: 95%;\n  padding: 20px;\n  background: #fff;\n  margin: 0px auto;\n  border: 2px solid rgba(245, 245, 245);\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.innerBookmarked_header h2 {\n  font-size: 1.8rem;\n  color: #000;\n  font-weight: 600;\n}\n\n.innerBookmarked_header button {\n  width: 45px;\n  height: 45px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #000;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  color: #fff;\n}\n\n.bookmarkedCardsInnerContainer {\n  width: 95%;\n  margin: 20px auto;\n  display: grid;\n  grid-template-columns: repeat(1, 1fr);\n  max-height: 0px;\n  overflow: hidden;\n  transition: 0.2s linear;\n}\n\n.bookmarkedCardsInnerContainer.active {\n  transition: 0.2s linear;\n  overflow: unset;\n  max-height: 5000px;\n}\n\n.bookmarkedCardsInnerContainer .searchedCardBx {\n  background: #fff;\n}\n.bookmarkedCardsInnerContainer .innerSearchedTextData h2 {\n  max-width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from "react";
import { FullScreenToggleStyle as defaultFullScreenToggleStyles, } from "./style";
import { useReader } from "../../hooks/useReaderContext";
import { BiFullscreen } from "react-icons/bi";
import { BiExitFullscreen } from "react-icons/bi";
var FullScreenToggle = function (_a) {
    var _b = _a.fullScreenToggleStyles, fullScreenToggleStyles = _b === void 0 ? defaultFullScreenToggleStyles : _b;
    var _c = useReader(), bookLocation = _c.bookLocation, fullScreen = _c.fullScreen, setFullScreen = _c.setFullScreen;
    useEffect(function () {
        var handleFullScreenChange = function () {
            setFullScreen(document.fullscreenElement !== null);
        };
        document.addEventListener("fullscreenchange", handleFullScreenChange);
        return function () {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
        };
    }, [setFullScreen]);
    function toggleFullScreen() {
        if (!bookLocation.current)
            return;
        var element = bookLocation.current;
        if (!document.fullscreenElement) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            }
            setFullScreen(true);
        }
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            setFullScreen(false);
        }
    }
    return (React.createElement(React.Fragment, null, fullScreen ? (React.createElement(BiExitFullscreen, { onClick: toggleFullScreen, size: 30, className: "cursor-pointer p-0.5 text-indigo-800 hover:text-gray-500" })) : (React.createElement(BiFullscreen, { onClick: toggleFullScreen, size: 30, className: "cursor-pointer p-0.5 hover:text-indigo-800 text-gray-500" }))));
};
export default FullScreenToggle;

import React from "react";
import { useReader } from "../../lib/hooks/useReaderContext";
export var Setting = function () {
    var _a = useReader(), userSettings = _a.userSettings, setUserSettings = _a.setUserSettings;
    var handleFontSizeChange = function (e) {
        setUserSettings({
            fontSize: e.target.value + "%",
            theme: userSettings.theme,
        });
    };
    return (React.createElement("div", { className: "h-full overflow-hidden flex flex-col" },
        React.createElement("p", { className: "font-semibold text-lg text-center py-2" }, "Settings"),
        React.createElement("div", { className: "p-3 flex flex-col justify-center items-start" },
            React.createElement("label", { htmlFor: "font", className: "text-gray-600 font-medium text-md" }, "Font Size"),
            React.createElement("input", { type: "number", min: 1, className: "border-2 border-gray-300 focus:border-gray-500 outline-none w-full p-1 rounded-md", value: userSettings.fontSize.split("%")[0], onChange: handleFontSizeChange }))));
};

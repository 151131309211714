import React, { useEffect, useState } from 'react';
import './authorcards.css';
import RatingCard from '../../RatingCard/RatingCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faChevronDown, faChevronUp, faDollarSign, faHeart, faNavicon, faRotateForward } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { useAuthors } from '@/contexts/AuthorContext';
import useStreamingText from '@/utils/Stream';
var AuthorCards = function (_a) {
    var author = _a.author, HandleDragStatus = _a.HandleDragStatus;
    var _b = useAuthors(), stats = _b.stats, setStats = _b.setStats, shelf = _b.shelf, setShelf = _b.setShelf, setBook = _b.setBook;
    if (!author) {
        return null;
    }
    var _c = useState(false), isFavourite = _c[0], setIsFavourite = _c[1];
    var _d = useState(false), flipped = _d[0], setFlipped = _d[1];
    var _e = useState(false), streamed = _e[0], setStreamed = _e[1];
    var streamedDescription = useStreamingText(author.description, 10, streamed);
    useEffect(function () {
        if (flipped && !streamed) {
            setStreamed(true);
        }
    }, [flipped]);
    var handleToggleCardFlip = function () {
        setFlipped(!flipped);
        setStats(null);
        setShelf(null);
        setBook(null);
    };
    return (React.createElement("div", { className: "mainAuthorCard ".concat(flipped ? 'flipped' : '') },
        React.createElement("div", { className: "AuthorInnerCardDetails" },
            React.createElement("div", { className: "innerAuthorCard flip-front" },
                React.createElement("div", { className: "AuthorCardImage" },
                    React.createElement("img", { src: "/images/authors/".concat(author.id, ".png"), alt: "" }),
                    React.createElement("div", { className: "favouriteButton" },
                        React.createElement("button", { onMouseEnter: function () { return HandleDragStatus(true); }, onTouchStart: function () { return HandleDragStatus(true); }, onMouseLeave: function () { return HandleDragStatus(false); }, onTouchEnd: function () {
                                setTimeout(function () {
                                    HandleDragStatus(false);
                                }, 1000);
                            } },
                            React.createElement(FontAwesomeIcon, { icon: faNavicon, color: 'gray', type: "regular", size: "sm" })),
                        React.createElement("button", { onClick: function () { return setIsFavourite(!isFavourite); } },
                            React.createElement(FontAwesomeIcon, { icon: isFavourite ? faHeart : faRegularHeart, color: isFavourite ? '#D7080D' : 'gray', type: "regular", size: "sm" })))),
                React.createElement("div", { className: "authorCardDetails" },
                    React.createElement("h2", null, author.id),
                    React.createElement("div", { className: "authorRatings" },
                        React.createElement(RatingCard, { size: 16 }))),
                React.createElement("div", { className: "authorCardCalltoActions" },
                    stats === author.id ? React.createElement("button", { onClick: function () { return setStats(null); } },
                        "Hide Details ",
                        React.createElement("span", null,
                            React.createElement(FontAwesomeIcon, { icon: faChevronUp, size: '1x', color: 'gray' }))) : React.createElement("button", { onClick: function () { return setStats(author.id); } },
                        "Show Details ",
                        React.createElement("span", null,
                            React.createElement(FontAwesomeIcon, { icon: faChevronDown, size: '1x', color: 'gray' }))),
                    React.createElement("button", { onClick: handleToggleCardFlip },
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faRotateForward, size: "lg", color: 'gray' }),
                        " ")),
                stats === author.id && React.createElement("div", { className: "authorCardStatsContainer" },
                    React.createElement("div", { className: "autorCardStatsDetails" },
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "TTS :"),
                            React.createElement("label", null, "3.6m")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "CPT :"),
                            React.createElement("label", null, "$0.024")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "30d :"),
                            React.createElement("label", null, "623K")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "MP :"),
                            React.createElement("label", null, "$15")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "CBT :"),
                            React.createElement("label", null, "$0.004")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "90d :"),
                            React.createElement("label", null, "923K")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "AP :"),
                            React.createElement("label", null, "--")),
                        React.createElement("div", { className: "authorCardStatsBx" },
                            React.createElement("h4", null, "Age :"),
                            React.createElement("label", null, "8 mo")),
                        React.createElement("div", { className: "authorCardStatsBx column" },
                            React.createElement("h4", null, "AI-Model "),
                            React.createElement("label", null, "Lamma-002")),
                        React.createElement("div", { className: "authorCardStatsBx column" },
                            React.createElement("h4", null, "Vectors "),
                            React.createElement("label", null, "Ada-002"))),
                    React.createElement("div", { className: "traningTokensDetails" },
                        React.createElement("h2", { className: 'traningTokenHeading' }, "Training"),
                        React.createElement("div", { className: "innerTrainingTkens" },
                            React.createElement("label", null, "#5.21G Tokens"),
                            React.createElement("label", null, "#35 Books"),
                            React.createElement("label", null, "#32 Bs"),
                            React.createElement("label", null, "#57,278 cells"),
                            React.createElement("label", null, "#5.21G Tokens"))))),
            React.createElement("div", { className: "authorCardBackContainer flip-back" },
                React.createElement("div", { className: "innerAuthorCardBackContainer" },
                    React.createElement("div", { className: "authorCardBackTags" }, author.category.map(function (item, index) {
                        return (React.createElement("label", { key: index },
                            "#",
                            item));
                    })),
                    React.createElement("div", { className: "authorCardBackDescription" },
                        React.createElement("p", null,
                            streamedDescription,
                            " ")),
                    React.createElement("div", { className: "authorCardBackCallToActions" },
                        React.createElement("button", { onClick: function () { return setShelf(shelf == author.id ? null : author.id); } },
                            " ",
                            React.createElement("label", null,
                                React.createElement(FontAwesomeIcon, { icon: faBook, size: 'sm', color: 'gray' })),
                            "  Read"),
                        React.createElement("button", { onClick: handleToggleCardFlip },
                            React.createElement(FontAwesomeIcon, { icon: faRotateForward, size: "1x", color: 'gray' })),
                        React.createElement("button", null,
                            React.createElement("label", null,
                                React.createElement(FontAwesomeIcon, { icon: faDollarSign, size: 'sm', color: 'gray' })),
                            " Buy")))))));
};
export default AuthorCards;

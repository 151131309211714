import React from 'react';
var CardDetailsFront = function (_a) {
    var messageData = _a.messageData, messageType = _a.messageType;
    var MaxText = 500;
    var MinifiedText = function (text) {
        var textLength = text.length;
        return textLength > MaxText ? React.createElement("p", null,
            text.slice(0, MaxText),
            ". ",
            React.createElement("button", null, "Show More Details")) : React.createElement("p", null, text);
    };
    return (React.createElement("div", { className: 'messageCardFrontDetails' }, messageType === 'input' ? MinifiedText(messageData.user_query) : MinifiedText(messageData.message)));
};
export default CardDetailsFront;

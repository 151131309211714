import React from 'react';
import useAuthorBooks from '../../utils/useAuthorBooks';
import { useSettings } from '../../contexts/SettingsContext';
var CardDetailsBack = function (_a) {
    var currentAuthorId = _a.currentAuthorId;
    var books = useAuthorBooks(currentAuthorId);
    var topBooksCount = useSettings().topBooksCount;
    var topNBooks = books.slice(0, topBooksCount);
    return (React.createElement("div", { className: "messageCardDetailsBack" },
        React.createElement("div", { className: "featureMessageCard" },
            React.createElement("p", null, "Sources by Relevance")), topNBooks === null || topNBooks === void 0 ? void 0 :
        topNBooks.map(function (book, index) {
            return (React.createElement("div", { className: "messageBookCard", key: index },
                React.createElement("h2", null, book.title),
                React.createElement("p", null, book.description)));
        })));
};
export default CardDetailsBack;

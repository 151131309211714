// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainSharePageContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0px auto;
}

.innerSharePageContainer {
  padding: 50px 0px;
}

.shareCardsMainContainer {
  width: 100%;
  margin: 0px auto;
}

.sharedCardsInnerContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  width: 95%;
  margin: 0px auto;
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s linear;
}

.sharedCardsInnerContainer.active {
  max-height: 4000px;
  overflow: unset;
  transition: 0.2s linear;
}
`, "",{"version":3,"sources":["webpack://./src/ugd_frontend/src/styles/sharePage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".mainSharePageContainer {\n  width: 100%;\n  max-width: 1400px;\n  margin: 0px auto;\n}\n\n.innerSharePageContainer {\n  padding: 50px 0px;\n}\n\n.shareCardsMainContainer {\n  width: 100%;\n  margin: 0px auto;\n}\n\n.sharedCardsInnerContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-gap: 25px;\n  width: 95%;\n  margin: 0px auto;\n  max-height: 0px;\n  overflow: hidden;\n  transition: 0.2s linear;\n}\n\n.sharedCardsInnerContainer.active {\n  max-height: 4000px;\n  overflow: unset;\n  transition: 0.2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// @ts-nocheck
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ReactDOM from 'react-dom';
import { cx, css } from '@emotion/css';
export var Button = React.forwardRef(function (_a, ref) {
    var className = _a.className, active = _a.active, reversed = _a.reversed, props = __rest(_a, ["className", "active", "reversed"]);
    return (React.createElement("span", __assign({}, props, { ref: ref, className: cx(className, css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          cursor: pointer;\n          color: ", ";\n        "], ["\n          cursor: pointer;\n          color: ", ";\n        "])), reversed
            ? active
                ? 'white'
                : '#aaa'
            : active
                ? 'black'
                : '#ccc')) })));
});
export var EditorValue = React.forwardRef(function (_a, ref) {
    var className = _a.className, value = _a.value, props = __rest(_a, ["className", "value"]);
    var textLines = value.document.nodes
        .map(function (node) { return node.text; })
        .toArray()
        .join('\n');
    return (React.createElement("div", __assign({ ref: ref }, props, { className: cx(className, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            margin: 30px -20px 0;\n          "], ["\n            margin: 30px -20px 0;\n          "])))) }),
        React.createElement("div", { className: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n            font-size: 14px;\n            padding: 5px 20px;\n            color: #404040;\n            border-top: 2px solid #eeeeee;\n            background: #f8f8f8;\n          "], ["\n            font-size: 14px;\n            padding: 5px 20px;\n            color: #404040;\n            border-top: 2px solid #eeeeee;\n            background: #f8f8f8;\n          "]))) }, "Slate's value as text"),
        React.createElement("div", { className: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n            color: #404040;\n            font: 12px monospace;\n            white-space: pre-wrap;\n            padding: 10px 20px;\n            div {\n              margin: 0 0 0.5em;\n            }\n          "], ["\n            color: #404040;\n            font: 12px monospace;\n            white-space: pre-wrap;\n            padding: 10px 20px;\n            div {\n              margin: 0 0 0.5em;\n            }\n          "]))) }, textLines)));
});
export var Icon = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("span", __assign({}, props, { ref: ref, className: cx('material-icons', className, css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          font-size: 28px;\n          vertical-align: text-bottom;\n          color: black;\n          background: #f8f8e8;\n        "], ["\n          font-size: 28px;\n          vertical-align: text-bottom;\n          color: black;\n          background: #f8f8e8;\n        "])))) })));
});
export var Instruction = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({}, props, { ref: ref, className: cx(className, css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          white-space: pre-wrap;\n          margin: 0 -20px 10px;\n          padding: 10px 20px;\n          font-size: 14px;\n          background: #f8f8e8;\n        "], ["\n          white-space: pre-wrap;\n          margin: 0 -20px 10px;\n          padding: 10px 20px;\n          font-size: 14px;\n          background: #f8f8e8;\n        "])))) })));
});
export var Menu = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({}, props, { "data-test-id": "menu", ref: ref, className: cx(className, css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          & > * {\n            display: inline-block;\n          }\n\n          & > * + * {\n            margin-left: 15px;\n          }\n        "], ["\n          & > * {\n            display: inline-block;\n          }\n\n          & > * + * {\n            margin-left: 15px;\n          }\n        "])))) })));
});
export var Portal = function (_a) {
    var children = _a.children;
    return typeof document === 'object'
        ? ReactDOM.createPortal(children, document.body)
        : null;
};
export var Toolbar = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(Menu, __assign({}, props, { ref: ref, className: cx(className, css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          position: relative;\n          padding: 1px 18px 17px;\n          margin: 0 -20px;\n          border-bottom: 2px solid #eee;\n          margin-bottom: 20px;\n        "], ["\n          position: relative;\n          padding: 1px 18px 17px;\n          margin: 0 -20px;\n          border-bottom: 2px solid #eee;\n          margin-bottom: 20px;\n        "])))) })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Epub, { EpubCFI } from "epubjs";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { PiFileCsvThin } from "react-icons/pi";
import { BsCart2, BsFiletypeJson } from "react-icons/bs";
import { Modal } from "antd";
import BookModal from "@/components/BooksCard/BookModal";
function titleToFileName(title) {
    return title
        .toLowerCase() // convert to lowercase
        .replace(/['"]/g, "") // remove apostrophes and quotes
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/[^\w-]/g, ""); // remove any non-word (excluding hyphens) characters
}
function removeNewLines(text) {
    return text.replace(/\r?\n|\r/g, "");
}
var NFTCard = function (_a) {
    var bookData = _a.bookData, _b = _a.view, view = _b === void 0 ? "grid" : _b;
    var bookAreaRef = useRef(null);
    var _c = useState(null), book = _c[0], setBook = _c[1];
    var _d = useState([]), contents = _d[0], setContents = _d[1];
    var _e = useState(null), cover = _e[0], setCover = _e[1];
    var _f = useState(false), isBookModalVisible = _f[0], setIsBookModalVisible = _f[1];
    useEffect(function () {
        if (bookAreaRef.current) {
            try {
                var onlineBook = Epub(bookData.url, { openAs: "epub" });
                // Render the book off-scr`een or hidden
                onlineBook.renderTo(bookAreaRef.current, {
                    width: 0,
                    height: 0,
                });
                setBook(onlineBook);
            }
            catch (error) {
                console.error("error", error);
            }
        }
    }, []);
    useEffect(function () {
        if (book) {
            setContents([]);
            setCover(null);
            book.loaded.cover.then(function (coverPath) {
                book.archive.createUrl(coverPath).then(function (url) {
                    setCover(url); // Set the URL for the cover image
                });
            });
            book.loaded.spine.then(function (spine) { return __awaiter(void 0, void 0, void 0, function () {
                var contents, _loop_1, _i, _a, item, state_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            contents = [];
                            _loop_1 = function (item) {
                                var doc, innerHTML, parsedDoc, paragraphs;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            if (!item.href)
                                                return [2 /*return*/, { value: void 0 }];
                                            return [4 /*yield*/, book.load(item.href)];
                                        case 1:
                                            doc = _c.sent();
                                            innerHTML = doc.documentElement
                                                .innerHTML;
                                            parsedDoc = new DOMParser().parseFromString(innerHTML, "text/html");
                                            paragraphs = parsedDoc.querySelectorAll("p");
                                            paragraphs.forEach(function (paragraph) {
                                                var _a, _b;
                                                var text = (_b = (_a = paragraph.textContent) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : "";
                                                if (text.length < 1)
                                                    return;
                                                var cfi = new EpubCFI(paragraph, item.cfiBase);
                                                var content = {
                                                    cfi: cfi,
                                                    text: removeNewLines(text),
                                                };
                                                contents.push(content);
                                            });
                                            return [2 /*return*/];
                                    }
                                });
                            };
                            _i = 0, _a = spine.items;
                            _b.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 4];
                            item = _a[_i];
                            return [5 /*yield**/, _loop_1(item)];
                        case 2:
                            state_1 = _b.sent();
                            if (typeof state_1 === "object")
                                return [2 /*return*/, state_1.value];
                            _b.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4:
                            setContents(contents);
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        else {
            console.log("no book");
        }
    }, [book]);
    var handleJSONDownload = function () {
        // <CSVLink filename={} headers={metadataHeaders} data={[metadata]} className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded">Export to JSON</CSVLink>
        var blob = new Blob([
            JSON.stringify({
                title: bookData.title,
                creator: bookData.creator,
                description: bookData.description,
                pubdate: bookData.pubdate,
                publisher: bookData.publisher,
                identifier: bookData.identifier,
                language: bookData.language,
                rights: bookData.rights,
                modified_date: bookData.modified_date,
            }),
        ], { type: "application/json" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = url;
        link.download = titleToFileName(bookData.title) + "_MetaData.json";
        link.click();
    };
    // Function to show the modal
    var showBookModal = function () {
        setIsBookModalVisible(true);
    };
    // Function to handle when the user clicks Cancel in the modal
    var handleCancel = function () {
        setIsBookModalVisible(false);
    };
    return (React.createElement(React.Fragment, null, view == "grid" ? (React.createElement("div", { className: "bg-white shadow rounded overflow-hidden flex flex-col justify-between" },
        React.createElement("div", { ref: bookAreaRef }),
        React.createElement("div", { className: "relative group" },
            cover ? (React.createElement("img", { src: cover, alt: bookData.title, className: "w-full h-52 object-cover" })) : (React.createElement("div", { role: "status", className: "flex items-center justify-center w-full h-52 bg-gray-400 animate-pulse" },
                React.createElement("svg", { className: "w-10 h-10 text-gray-200 dark:text-gray-600", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 20 18" },
                    React.createElement("path", { d: "M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" })),
                React.createElement("span", { className: "sr-only" }, "Loading..."))),
            React.createElement("div", { className: "w-full h-full absolute inset-0 bg-gray-400 bg-opacity-40 opacity-0 group-hover:opacity-100 transition-all duration-500" },
                React.createElement("button", { onClick: showBookModal, className: "absolute left-0 bottom-0 w-full p-2 bg-gray-800 text-white text-base text-center leading-4 flex items-center justify-center" },
                    React.createElement("span", { className: "text-white mr-1" },
                        React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 32 32" },
                            React.createElement("path", { fill: "currentColor", d: "M16 8C7.664 8 1.25 15.344 1.25 15.344L.656 16l.594.656s5.848 6.668 13.625 7.282c.371.046.742.062 1.125.062s.754-.016 1.125-.063c7.777-.613 13.625-7.28 13.625-7.28l.594-.657l-.594-.656S24.336 8 16 8zm0 2c2.203 0 4.234.602 6 1.406A6.89 6.89 0 0 1 23 15a6.995 6.995 0 0 1-6.219 6.969c-.02.004-.043-.004-.062 0c-.239.011-.477.031-.719.031c-.266 0-.523-.016-.781-.031A6.995 6.995 0 0 1 9 15c0-1.305.352-2.52.969-3.563h-.031C11.717 10.617 13.773 10 16 10zm0 2a3 3 0 1 0 .002 6.002A3 3 0 0 0 16 12zm-8.75.938A9.006 9.006 0 0 0 7 15c0 1.754.5 3.395 1.375 4.781A23.196 23.196 0 0 1 3.531 16a23.93 23.93 0 0 1 3.719-3.063zm17.5 0A23.93 23.93 0 0 1 28.469 16a23.196 23.196 0 0 1-4.844 3.781A8.929 8.929 0 0 0 25 15c0-.715-.094-1.398-.25-2.063z" }))),
                    "Quick View"),
                React.createElement(Modal, { centered: true, open: isBookModalVisible, onCancel: handleCancel, footer: null, closable: false, width: "70rem", 
                    // width={'md:aspect-video aspect-[3/4] w-full h-full'}
                    classNames: { content: "!p-0" } },
                    React.createElement(BookModal, { bookUrl: bookData.url })))),
        React.createElement("div", { className: "py-2 px-4 flex-grow flex flex-col justify-center" },
            React.createElement("h4", { className: " font-mono font-semibold text-md pt-1 text-gray-800 hover:text-blue-500 transition" }, bookData.creator.length > 20
                ? bookData.creator.substring(0, 20) + "..."
                : bookData.creator),
            React.createElement("p", { className: "font-mono font-regular text-base text-gray-600 hover:text-blue-500 transition" }, bookData.title.length > 40
                ? bookData.title.substring(0, 40) + "..."
                : bookData.title)),
        React.createElement("div", { className: "flex flex-wrap justify-between items-center w-full rounded text-sm font-semibold gap-2 p-2" },
            React.createElement("button", { onClick: handleJSONDownload, className: "px-2 py-1 rounded-tl rounded-bl transition flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold rounded flex justify-center items-center gap-1" },
                React.createElement(BsFiletypeJson, { size: 18 }),
                "Metadata"),
            React.createElement(CSVLink, { filename: titleToFileName(bookData.title) +
                    "_Contents.csv", headers: [
                    { label: "cfi", key: "cfi" },
                    { label: "text", key: "text" },
                ], data: contents, className: "px-2 py-1 rounded-tr rounded-br transition flex-grow font-bold rounded flex justify-center items-center gap-1 ".concat(contents && contents.length > 0 ? "bg-blue-500 hover:bg-blue-700 text-white" : "pointer-events-none bg-gray-300", " ") },
                React.createElement(PiFileCsvThin, { size: 18 }),
                "Content"),
            React.createElement("button", { onClick: function () {
                    return alert("handle listing the book in the marketplace");
                }, className: "px-2 py-1 rounded-tl rounded-bl transition flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold rounded flex justify-center items-center gap-1" },
                React.createElement(BsCart2, { size: 18 }),
                "Sell in Marketplace")))) : (React.createElement("tr", { className: "bg-white border-b " },
        React.createElement("td", { className: "py-4 px-6" },
            React.createElement("div", { ref: bookAreaRef }),
            cover ? (React.createElement("img", { src: cover, alt: bookData.title, className: "w-20 h-20 object-cover" })) : (React.createElement("div", { role: "status", className: "flex items-center justify-center w-20 h-20 bg-gray-400 animate-pulse" },
                React.createElement("svg", { className: "w-10 h-10 text-gray-200 dark:text-gray-600", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 20 18" },
                    React.createElement("path", { d: "M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" })),
                React.createElement("span", { className: "sr-only" }, "Loading...")))),
        React.createElement("td", { className: "py-4 px-6" }, bookData.title),
        React.createElement("td", { className: "py-4 px-6" }, bookData.creator),
        React.createElement("td", { className: "py-4 px-6" },
            React.createElement("div", { className: "relative grid items-center justify-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md" },
                React.createElement("span", { className: "" }, "Minted"))),
        React.createElement("td", { className: "py-4 px-6" },
            React.createElement("div", { className: "flex flex-col justify-between items-stretch w-full rounded text-sm font-semibold gap-2 p-2" },
                React.createElement("button", { onClick: handleJSONDownload, className: "px-2 py-1 rounded-tl rounded-bl transition flex-grow bg-blue-500 hover:bg-blue-700 text-white font-bold rounded flex justify-center items-center gap-1" },
                    React.createElement(BsFiletypeJson, { size: 18 }),
                    "Metadata"),
                React.createElement(CSVLink, { filename: titleToFileName(bookData.title) +
                        "_Contents.csv", headers: [
                        { label: "cfi", key: "cfi" },
                        { label: "text", key: "text" },
                    ], data: contents, className: "px-2 py-1 rounded-tr rounded-br transition flex-grow  font-bold rounded flex justify-center items-center gap-1  ".concat(contents && contents.length > 0 ? "bg-blue-500 hover:bg-blue-700 text-white" : "pointer-events-none bg-gray-300", " ") },
                    React.createElement(PiFileCsvThin, { size: 18 }),
                    "Content"))),
        React.createElement("td", { className: "py-4 px-6" },
            React.createElement("button", { onClick: showBookModal, className: "hover:text-blue-500" },
                React.createElement("svg", { height: "24", width: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor" },
                    React.createElement("g", { id: "SVGRepo_bgCarrier", strokeWidth: "0" }),
                    React.createElement("g", { id: "SVGRepo_tracerCarrier", strokeLinecap: "round", strokeLinejoin: "round" }),
                    React.createElement("g", { id: "SVGRepo_iconCarrier" },
                        React.createElement("path", { d: "M12.5 18c-5.708 0-10.212-5.948-10.4-6.201l-.224-.299.224-.299C2.288 10.948 6.792 5 12.5 5s10.212 5.948 10.4 6.201l.224.299-.224.299C22.712 12.052 18.208 18 12.5 18zm-9.36-6.5c.98 1.188 4.85 5.5 9.36 5.5s8.38-4.312 9.36-5.5C20.88 10.312 17.01 6 12.5 6s-8.38 4.312-9.36 5.5zM12.5 8a3.5 3.5 0 1 0 3.5 3.5A3.5 3.5 0 0 0 12.5 8zm0 6a2.5 2.5 0 1 1 2.5-2.5 2.503 2.503 0 0 1-2.5 2.5z" }),
                        React.createElement("path", { fill: "none", d: "M0 0h24v24H0z" })))),
            React.createElement(Modal, { centered: true, open: isBookModalVisible, onCancel: handleCancel, footer: null, closable: false, width: "70rem", 
                // width={'md:aspect-video aspect-[3/4] w-full h-full'}
                classNames: { content: "!p-0" } },
                React.createElement(BookModal, { bookUrl: bookData.url })))))));
};
export default NFTCard;

import React, { useState } from "react";
var Accordion = function (_a) {
    var title = _a.title, body = _a.body, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b;
    var _c = useState(isOpen), accordionOpen = _c[0], setAccordionOpen = _c[1];
    return (React.createElement("div", { className: "p-2" },
        React.createElement("div", { className: " p-2 my-2 bg-white rounded-md" },
            React.createElement("button", { onClick: function () { return setAccordionOpen(!accordionOpen); }, className: "flex justify-between w-full items-center" },
                React.createElement("span", { className: "font-semibold" }, title),
                React.createElement("svg", { className: "fill-indigo-500 shrink-0 ml-8", width: "16", height: "16", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("rect", { y: "7", width: "16", height: "2", rx: "1", className: "transform origin-center transition duration-200 ease-out ".concat(accordionOpen && "!rotate-180") }),
                    React.createElement("rect", { y: "7", width: "16", height: "2", rx: "1", className: "transform origin-center rotate-90 transition duration-200 ease-out ".concat(accordionOpen && "!rotate-180") })))),
        React.createElement("div", { className: "grid transition-all duration-300 ease-in-out  text-sm ".concat(accordionOpen
                ? "h-auto overflow-auto grid-rows-[1fr] opacity-100"
                : "h-0 overflow-hidden grid-rows-[0fr] opacity-0") }, body)));
};
export default Accordion;

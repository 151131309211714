import React from "react";
import { TocItemStyle as defaultTocItemStyles, } from "./style";
import { useReader, useSidebar } from "../../hooks/useReaderContext";
export var TocItem = function (_a) {
    var tocItem = _a.tocItem, _b = _a.tocItemStyles, tocItemStyles = _b === void 0 ? defaultTocItemStyles : _b;
    var rendition = useReader().rendition;
    var setSidebar = useSidebar().setSidebar;
    var handleTocItemClick = function (i) {
        rendition.current && rendition.current.display(i.href);
        setSidebar(null);
    };
    return (React.createElement("div", { style: tocItemStyles.item },
        React.createElement("button", { onClick: function () { return handleTocItemClick(tocItem); }, style: tocItemStyles.itemButton, className: "text-gray-500 hover:text-gray-700 px-0 py-1 " }, tocItem.label),
        tocItem.subitems && tocItem.subitems.length > 0 && (React.createElement("div", { className: "pl-3 pb-0" }, tocItem.subitems.map(function (item, i) { return (React.createElement(TocItem, { key: i, tocItem: item })); })))));
};

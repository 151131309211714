var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from "react";
import { getCurLocaleTime } from "../utils";
export default function useBookmarkState() {
    var _a = useState([]), bookmarks = _a[0], setBookmarks = _a[1];
    var addBookmark = function (bookmark) {
        if (!bookmark.time)
            bookmark.time = getCurLocaleTime();
        setBookmarks(__spreadArray(__spreadArray([], bookmarks, true), [bookmark], false));
    };
    var removeBookmark = function (location) {
        var bookmarksFilter = bookmarks.filter(function (bookmark) { return bookmark.location.start.cfi !== (location === null || location === void 0 ? void 0 : location.start.cfi); });
        setBookmarks(bookmarksFilter);
    };
    return {
        bookmarks: bookmarks,
        addBookmark: addBookmark,
        removeBookmark: removeBookmark,
    };
}

import React, { useState } from 'react';
import ReactStars from 'react-rating-star-with-type';
var RatingCard = function (_a) {
    var value = _a.value, size = _a.size;
    var _b = useState(5), star = _b[0], setStar = _b[1];
    var onChange = function (nextValue) {
        setStar(nextValue);
    };
    return (React.createElement(ReactStars, { onChange: onChange, value: value || 5, size: size || 20, isEdit: true, activeColors: ["#8568FC",] }));
};
export default RatingCard;

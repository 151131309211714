var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import SearchedCards from './SearchedCards';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
var PreviewCard = function (_a) {
    var selectedSourceCards = _a.selectedSourceCards, setSelectedSourceCards = _a.setSelectedSourceCards;
    var _b = useState(true), isOpened = _b[0], setIsOpened = _b[1];
    var grid = selectedSourceCards.length;
    var reorder = function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        var items = reorder(selectedSourceCards, result.source.index, result.destination.index);
        setSelectedSourceCards(items);
    }
    var getItemStyle = function (isDragging, draggableStyle) { return (__assign({ userSelect: "none", padding: grid * 2, margin: "0 0 ".concat(grid, "px 0"), background: isDragging ? "lightgreen" : "#fff" }, draggableStyle)); };
    var getListStyle = function (isDraggingOver) { return ({
        background: isDraggingOver ? "lightblue" : "#fff",
        padding: grid,
    }); };
    return (React.createElement("div", { className: 'mainPreviewCardsContainer' },
        React.createElement("div", { className: "innerPreviewContainer" },
            React.createElement("div", { className: "previewContainerHeader" },
                React.createElement("h2", null, "Preview Card"),
                React.createElement("button", { onClick: function () { return setIsOpened(!isOpened); } },
                    React.createElement(FontAwesomeIcon, { icon: isOpened ? faChevronUp : faChevronDown, size: 'sm' }))),
            React.createElement("div", { className: !isOpened ? "innerPreviewCardsContainerBx hide" : "innerPreviewCardsContainerBx" },
                React.createElement("div", { className: "PreviewCardsContainerBx" },
                    React.createElement("div", { className: "previewCardBx" },
                        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
                            React.createElement(Droppable, { droppableId: "droppable" }, function (provided, snapshot) { return (React.createElement("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, style: getListStyle(snapshot.isDraggingOver) }), selectedSourceCards === null || selectedSourceCards === void 0 ? void 0 :
                                selectedSourceCards.map(function (item, index) {
                                    var uniqueId = item.title + index;
                                    return (React.createElement(Draggable, { key: uniqueId, draggableId: uniqueId, index: index }, function (provided, snapshot) { return (React.createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }),
                                        React.createElement(SearchedCards, { item: item, isHideCta: true }))); }));
                                }),
                                provided.placeholder)); }))),
                    React.createElement("div", { className: "previewCardCta" },
                        React.createElement("button", null, "Sumarize"),
                        React.createElement("button", null, "Ai Output"),
                        React.createElement("button", null, "Rating"),
                        React.createElement("button", null, "Optimze Results")))))));
};
export default PreviewCard;

export var AUTHOR_INFO = [
    {
        "id": "Benjamin Franklin",
        "category": ["Philosophy", "Science", "American History", "Politics", "Inventions", "Philosophy", "Government", "Pioneer"],
        "cluster": "Benjamin_Franklin",
        "image": "Benjamin Franklin.png",
        "description": "Pioneering Visionary & Distinguished Polymath (1706-1790) | Trailblazer in the Discovery of Electricity & Foundational Contributor to the Birth of the United States | Illustrious Founder of the American Philosophical Association | Co-Author of the Declaration of Independence | A luminary who, despite his monumental achievements, once humbly professed, 'I may not be a man of unparalleled greatness, but I am, undeniably, a man of virtue and worth.'",
        "books": [
            "The Complete Works in Philosophy, Politics and Morals of the late Dr. Benjamin Franklin, Vol. 1 [of 3]",
            "The Complete Works in Philosophy, Politics and Morals of the late Dr. Benjamin Franklin, Vol. 2 [of 3]",
            "The Complete Works in Philosophy, Politics and Morals of the late Dr. Benjamin Franklin, Vol. 3 [of 3]",
        ],
        "book_descriptions": [
            "Volume 1 presents an extensive compilation of Benjamin Franklin’s thoughts, covering his insights into philosophy and showcasing his intellect and rational approach to life’s various aspects.",
            "In Volume 2, the exploration into Franklin's works continues, presenting his perspectives on politics, his views on governance, democracy, and the foundational principles that underlie the American political landscape.",
            "Volume 3 concludes the series, delving into Franklin’s moral views, his understanding of human nature, ethics, and the guiding principles that he believed should underpin a just and prosperous society."
        ],
    },
    {
        "id": "The Bible",
        "category": ["Religion", "Faith", "Christianity", "Orthodoxy", "Islam", "Jesus", "God", "Judaism", "Mysticism", "Life"],
        "cluster": "The_Bible",
        "image": "The Bible.png",
        "description": "The Entire King James Bible, Separated and Indexed by Verse.",
        "books": [
            "Amos",
            "Ecclesiastes",
            "Ezra",
            "Habakkuk",
            "Haggai",
            "Hosea",
            "Joel",
            "Jonah",
            "Malachi",
            "Micah",
            "Nahum",
            "Obadiah",
            "The Acts of the Apostles",
            "The Book of Daniel",
            "The Book of Esther",
            "The Book of Job",
            "The Book of Joshua",
            "The Book of Judges",
            "The Book of Nehemiah",
            "The Book of Psalms",
            "The Book of Ruth",
            "The Book of the Prophet Ezekiel",
            "The Book of the Prophet Isaiah",
            "The Book of the Prophet Jeremiah",
            "The Epistle of Paul the Apostle to Philemon",
            "The Epistle of Paul the Apostle to the Colossians",
            "The Epistle of Paul the Apostle to the Ephesians",
            "The Epistle of Paul the Apostle to the Galatians",
            "The Epistle of Paul the Apostle to the Hebrews",
            "The Epistle of Paul the Apostle to the Philippians",
            "The Epistle of Paul the Apostle to the Romans",
            "The Epistle of Paul the Apostle to Titus",
            "The Fifth Book of Moses Called Deuteronomy",
            "The First Book of Moses Called Genesis",
            "The First Book of Samuel",
            "The First Book of the Chronicles",
            "The First Book of the Kings",
            "The First Epistle General of John",
            "The First Epistle General of Peter",
            "The First Epistle of Paul the Apostle to the Corinthians",
            "The First Epistle of Paul the Apostle to the Thessalonians",
            "The First Epistle of Paul the Apostle to Timothy",
            "The Fourth Book of Moses Called Numbers",
            "The General Epistle of James",
            "The General Epistle of Jude",
            "The Gospel According to Saint John",
            "The Gospel According to Saint Luke",
            "The Gospel According to Saint Mark",
            "The Gospel According to Saint Matthew",
            "The Lamentations of Jeremiah",
            "The Proverbs",
            "The Revelation of Saint John the Divine",
            "The Second Book of Moses Called Exodus",
            "The Second Book of Samuel",
            "The Second Book of the Chronicles",
            "The Second Book of the Kings",
            "The Second Epistle General of John",
            "The Second Epistle of Paul the Apostle to the Corinthians",
            "The Second Epistle of Paul the Apostle to the Thessalonians",
            "The Second Epistle of Paul the Apostle to Timothy",
            "The Second General Epistle of Peter",
            "The Song of Solomon",
            "The Third Book of Moses Called Leviticus",
            "The Third Epistle General of John",
            "Zechariah",
            "Zephaniah",
        ],
        book_descriptions: [
            "Amos delivers harsh warnings to the Northern Kingdom of Israel, condemning them for their sins and injustices, and foretelling of destruction and exile.",
            "Ecclesiastes delves into the futility of human endeavors, urging readers to find fulfillment and joy in the fear of God rather than the pursuits of life.",
            "Ezra narrates the return of the Jews from Babylonian exile and their endeavors to rebuild the temple in Jerusalem.",
            "Habakkuk consists of dialogues between the prophet and God, addressing the problem of why God allows suffering and injustice.",
            "Haggai encourages the Jewish people to rebuild the temple, assuring them of God’s presence and support.",
            "Hosea’s marriage to an unfaithful wife serves as a metaphor for the relationship between God and Israel.",
            "Joel prophesizes a locust plague and a future divine judgment, calling people to repentance and emphasizing God’s mercy.",
            "Jonah tells the story of the reluctant prophet who tries to flee from God’s command to preach repentance to the city of Nineveh.",
            "Malachi addresses the spiritual indifference of the Israelites after their return from exile and calls them back to faithfulness.",
            "Micah brings a message of judgment and hope to both Israel and Judah, highlighting God’s justice and mercy.",
            "Nahum prophesizes the fall of Nineveh, bringing a message of comfort to the oppressed people of Judah.",
            "Obadiah conveys God’s judgment against the nation of Edom, who betrayed the people of Judah.",
            "The Acts of the Apostles documents the early history of the Christian Church, chronicling the actions of Jesus’ apostles after His ascension.",
            "The Book of Daniel tells of Daniel’s experiences and visions in Babylon, including prophecies of future kingdoms and the end times.",
            "The Book of Esther recounts how Esther, a Jewish queen, bravely saves her people from extermination in Persia.",
            "The Book of Job explores themes of suffering and patience as Job endures great loss and illness, seeking understanding.",
            "The Book of Joshua describes the conquest and settlement of Canaan by the Israelites under Joshua’s leadership.",
            "The Book of Judges tells of the leaders who governed Israel between Joshua and Samuel, detailing their victories and failures.",
            "The Book of Nehemiah details Nehemiah’s efforts to rebuild Jerusalem's walls and restore the community after exile.",
            "The Book of Psalms is a collection of religious songs, prayers, and poems, expressing the emotions and experiences of the psalmists.",
            "The Book of Ruth tells the story of Ruth and Naomi, exemplifying loyalty, kindness, and God’s providential care.",
            "The Book of the Prophet Ezekiel contains Ezekiel’s prophecies, including visions of the fall of Jerusalem and the restoration of Israel.",
            "The Book of the Prophet Isaiah presents Isaiah’s prophecies, foretelling the Messiah and calling Israel to repentance.",
            "The Book of the Prophet Jeremiah contains Jeremiah’s messages of warning to Judah and predictions of its fall.",
            "Paul’s letter to Philemon addresses the issue of slavery, appealing for grace and reconciliation.",
            "Paul’s letter to the Colossians affirms Christ’s supremacy and counters false teachings within the church.",
            "Paul's letter to the Ephesians expounds on the themes of unity in Christ and the Christian’s calling in the world.",
            "Paul’s letter to the Galatians defends the true gospel against judaizing influences, highlighting justification by faith.",
            "The letter to the Hebrews elaborates on Christ’s supremacy and the New Covenant, encouraging perseverance in faith.",
            "Paul’s letter to the Philippians emphasizes joy, unity, and humility, providing insight into Christian living.",
            "Paul’s letter to the Romans outlines key doctrines concerning sin, salvation, and Christian living.",
            "Paul’s letter to Titus provides instructions for church leadership and practical Christian living.",
            "Deuteronomy, the fifth book of Moses, recounts Israel’s journey and reiterates the laws and covenant established by God.",
            "Genesis, the first book of Moses, tells the story of creation, early humanity, and the origins of the Israelite people.",
            "The First Book of Samuel recounts the establishment of the monarchy in Israel under Saul and David.",
            "The First Book of the Chronicles provides a genealogical record and history of the Davidic kingdom.",
            "The First Book of the Kings chronicles the reigns of Solomon and his successors in Israel and Judah.",
            "John's first epistle assures believers of their eternal life and encourages love, obedience, and discernment.",
            "Peter's first epistle comforts suffering Christians and exhorts them to holy living.",
            "Paul’s first letter to the Corinthians addresses various moral and doctrinal issues facing the Corinthian church.",
            "Paul’s first letter to the Thessalonians encourages the new believers in Thessalonica and addresses questions about the second coming of Christ.",
            "Paul’s first letter to Timothy provides guidance for Timothy’s leadership in the Ephesian church.",
            "The Fourth Book of Moses, Numbers, narrates the Israelites’ wilderness journey and their preparations to enter Canaan.",
            "James’s general epistle gives practical advice for Christian living and emphasizes faith and works.",
            "Jude’s general epistle warns against false teachers and encourages Christians to persevere in the faith.",
            "An account that emphasizes the deep love and compassion of Jesus, focusing on his miracles, his crucifixion, and his divine relationship with God the Father.",
            "A narrative presenting a compassionate and detailed account of the life of Jesus, with an emphasis on his kindness and concern for the marginalized, including a significant amount of Jesus's teachings.",
            "A concise account of Jesus’s life, focusing on his deeds, his service, and his swift path to crucifixion, presenting a direct and powerful portrayal of his life and mission.",
            "A comprehensive narrative that begins with Jesus’s genealogy and includes significant teachings, such as the Sermon on the Mount, providing a rich and thorough account of Jesus’s life and mission.",
            "A poetic and profound expression of grief and sorrow, recounting the destruction of Jerusalem by Babylon in 586 B.C.",
            "A collection of wise sayings and guidance on living a righteous and prosperous life, traditionally attributed to King Solomon.",
            "A prophetic and symbolic vision of the end of the world, offering hope and assurance of God’s ultimate victory over evil.",
            "A historical account of the Israelites' escape from Egypt and their journey to Mount Sinai under the leadership of Moses, including the giving of the Ten Commandments.",
            "A historical narrative focusing on the reign of King David, his triumphs, failures, and the moral and political challenges he faced.",
            "A record detailing the history of Israel and Judah, focusing on their religious revival and the rulers who led them back to the worship of God.",
            "A historical narrative of the kings of ancient Israel, detailing their reigns, the events during their rule, and the impact of their leadership on the nation.",
            "A brief letter that emphasizes love and truth and warns against false teachings, encouraging adherence to the commandments of Jesus Christ.",
            "A letter providing counsel and encouragement to the Corinthians, addressing issues of division, morality, and the resurrection of Jesus Christ.",
            "A letter to the Thessalonians, offering guidance for living a Christian life, encouraging preparedness for the return of Jesus Christ, and offering comfort and assurance in the resurrection.",
            "A personal letter to Timothy, providing guidance for church leadership and encouragement for perseverance in the faith.",
            "A letter offering counsel on vigilance against false teachings, the importance of knowledge, and the assurance of God’s promise of eternal life.",
            "A poetic book celebrating the expression of love within the bounds of marriage, traditionally attributed to King Solomon.",
            "A detailed outline of the laws, rituals, and practices of the Israelites, providing instructions for worship and community life.",
            "A brief letter emphasizing the importance of love, truth, and faithful adherence to the teachings of Jesus Christ.",
            "A book of prophesy, filled with visions of the restoration of Jerusalem, the coming of the Messiah, and the future triumph of good over evil.",
            "A short prophetic book foretelling the destruction of Jerusalem and Judah, and the eventual restoration of God's people."
        ],
    },
    {
        "id": "Carl Jung",
        "category": ["Psychology", "Mysticism", "Philosophy", "Religion", "Spirituality", "Life", "Psyche", "Analytical Psychology", "Archetypes", "Individuation", "Symbolic Life", "Dreams"],
        "cap_first": "Carl",
        "cluster": "Carl_Jung",
        "first": "carl",
        "last": "jung",
        "image": "carlpfp.png",
        "description": "Psychiatrist & Psychoanalyst (1875-1961) | Founder of Analytical Psychology | Pioneer of the collective unconscious, archetypes, & individuation | \"Show me a sane man and I will cure him for you.\"",
        "books": ["Psychiatric Studies",
            "Psychogenesis of Mental Disease",
            "Freud and Psychoanalysis",
            "Symbols of Transformation",
            "Psychological Types",
            "Analytical Psychology",
            "Structure and Dynamics of the Psyche",
            "AION I Archetypes and the Collective Unconscious",
            "AION II Researches into the Phenomenology of the Self",
            "Civilization in Transition",
            "Psychology and Religion West and East",
            "Psychology and Alchemy",
            "Alchemical Studies",
            "Mysterium Coniunctionis",
            "Spirit in Man, Art, and Literature",
            "Practice of Psychotherapy",
            "Development of Personality",
            "The Symbolic Life"
        ],
        "book_descriptions": [
            "An exploration into diverse psychiatric cases, providing insight into the developing field of psychiatry in the early 20th century.",
            "An in-depth examination into the origins of mental diseases, proposing theories related to unconscious factors and early experiences.",
            "An exploration of Freud's theories, offering critical insight and comparison with Jung's own perspectives on psychoanalysis.",
            "A deep dive into the significant symbols of transformation and their role within the human psyche.",
            "A detailed discussion on psychological types, exploring the dimensions of introversion and extraversion, thinking and feeling, and other psychological distinctions.",
            "An introduction to Jung's foundational concepts in analytical psychology, laying out theories on the human mind and its connection to the world.",
            "An examination of the structure and dynamics of the psyche, including its different components and their interactions.",
            "Volume 1 focuses on archetypes and the collective unconscious, elaborating on the universal symbols and ideas present in the human mind.",
            "Volume 2 presents research into the phenomenology of the self, exploring concepts of identity and individuality.",
            "A reflective analysis of the challenges facing civilization, including discussions on societal upheaval and the clash of cultures.",
            "A comparative exploration of psychological perspectives on religion in both Western and Eastern contexts.",
            "An exploration into the connections between psychology and alchemy, delving into alchemical symbolism and its psychological interpretations.",
            "A study on alchemical symbolism and its relevance to psychoanalytical interpretations.",
            "An in-depth exploration of the Mysterium Coniunctionis, an alchemical concept representing the union of opposites.",
            "An examination of the role of spirit in various forms of human expression including art and literature.",
            "Insights into the practical aspects of psychotherapy, offering guidance and perspective for practitioners.",
            "A discussion on the development of personality, examining the factors influencing personality formation and growth.",
            "A collection of various writings, exploring symbolic expression in human life and its relevance to understanding the human experience.",
        ],
        "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Carl%20Jung/jung_books_sentences.json',
        "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Carl%20Jung/jung_books_segments.json',
        "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Carl%20Jung/jung_books_paragraphs.json'
    },
    {
        "id": "Charles Darwin",
        "category": ["Naturalism", "Science", "Evolution", "Discoveries", "Galapagos", "Birds", "Species", "Animals", "Plants", "Fossils", "Geology"],
        "cap_first": "Darwin",
        "cluster": "Charles_Darwin",
        "first": "charles",
        "last": "darwin",
        "image": "darwinpfp.png",
        "description": "Naturalist & Evolutionary Biologist (1809-1882) | Originator of the theory of evolution by natural selection | Wrote 20 books on evolution, geology, & biology | \"It is not the strongest of the species that survives, nor the most intelligent that survives. It is the one that is the most adaptable to change.\"",
        "books": ['The Voyage of the Beagle',
            'The Expression of the Emotions in Man and Animals',
            'The Origin of Species by Means of Natural Selection Or, the Preservation of Favoured Races in the Struggle for Life, 6th Edition',
            'The Autobiography of Charles Darwin',
            'Life and Letters of Charles Darwin — Volume 1',
            'Life and Letters of Charles Darwin — Volume 2',
            'The Descent of Man, and Selection in Relation to Sex',
            'Note on the Resemblances and Differences in the Structure and the Development of the Brain in Man and Apes',
            'The Formation of Vegetable Mould Through the Action of Worms With Observations on Their Habits',
            'The Movements and Habits of Climbing Plants',
            'More Letters of Charles Darwin — Volume 1 A Record of His Work in a Series of Hitherto Unpublished Letters',
            'More Letters of Charles Darwin — Volume 2 A Record of His Work in a Series of Hitherto Unpublished Letters',
            'The Variation of Animals and Plants under Domestication — Volume 1',
            'The Variation of Animals and Plants under Domestication — Volume 2',
            'Journal of Researches into the Natural History and Geology of the Countries Visited During the Voyage Round the World of H.M.S. Beagle Under the Command of Captain Fitz Roy, R.N.',
            'The Different Forms of Flowers on Plants of the Same Species',
            'Coral Reefs; Volcanic Islands; South American Geology — Complete',
            'The Effects of Cross & Self-Fertilisation in the Vegetable Kingdom',
            'The Power of Movement in Plants',
            'Insectivorous Plants',
            'The Foundations of the Origin of Species Two Essays written in 1842 and 1844',
            'A Monograph on the Sub-class Cirripedia (Volume 1 of 2) The Lepadidae; Or, Pedunculated Cirripedes',
            'Charles Darwin  His Life Told in an Autobiographical Chapter, and in a Selected Series of His Published Letters',
            'A Monograph on the Sub-class Cirripedia (Volume 2 of 2) The Balanidae, (or Sessile Cirripedes); the Verrucidae, etc., etc.',
            'The zoology of the voyage of H.M.S. Beagle [vol. 1 of 5]  Fossil mammalia',
            'Darwinism stated by Darwin himself Characteristic passages from the writings of Charles Darwin'
        ],
        "book_descriptions": [
            'A detailed narrative of Charles Darwin’s adventurous expedition on the HMS Beagle that shaped his understanding of biology and laid the groundwork for the theory of evolution.',
            'An exploration of the various ways emotions manifest in humans and animals, supported by rich observations and intricate illustrations.',
            'The seminal work where Darwin presents his groundbreaking theory of evolution, explaining how species adapt and evolve over generations.',
            'An intimate look into the life of Charles Darwin, written by the man himself, offering insights into his personal and scientific journey.',
            'The first volume of a compilation of letters and correspondences by Charles Darwin, providing a closer look at his life and work.',
            'Continuing from the first volume, this book offers more letters and insights into the life and scientific explorations of Charles Darwin.',
            'Darwin’s exploration into human evolution and the role of sexual selection in the development of human attributes and behavior.',
            'A scientific note in which Darwin details the anatomical similarities and differences between humans and apes, focusing on brain structure.',
            'An exploration into the world of earthworms and their role in the formation of vegetable mould, reflecting on their habits and impact on the earth’s soil.',
            'A study into the movements and habits of climbing plants, shedding light on their adaptation and survival mechanisms.',
            'The first volume presenting a collection of unpublished letters by Charles Darwin, offering further insight into his work and thoughts.',
            'Continuing with more letters, this second volume sheds more light on Darwin’s unpublished thoughts and correspondences.',
            'A comprehensive study on the variation observed in animals and plants under domestication, offering insights into artificial selection.',
            'A comprehensive study on the variation observed in animals and plants under domestication, offering insights into artificial selection.',
            'A detailed journal chronicling the natural history and geology of the countries visited during the voyage of the HMS Beagle.',
            'An examination into the different forms of flowers on the same species of plants, exploring diversity and adaptation.',
            'A complete volume presenting Darwin’s works on coral reefs, volcanic islands, and South American geology.',
            'A study into the effects of cross and self-fertilisation in plants and their impact on plant evolution and adaptation.',
            'An exploration into the fascinating world of plant movement, examining the various ways plants adapt to their environment.',
            'A detailed study on insectivorous plants, shedding light on their unique adaptation and survival mechanisms.',
            'Two essays written by Darwin in 1842 and 1844 laying down the foundations for his later work on the theory of evolution.',
            'The first volume of a detailed monograph on the sub-class Cirripedia, focusing on Lepadidae or pedunculated cirripedes.',
            'A biographical account combining Darwin’s autobiographical chapter and a series of his published letters, offering a comprehensive look at his life.',
            'The second volume of the monograph on Cirripedia, focusing on the Balanidae or sessile cirripedes, the Verrucidae, and others.',
            'The first volume of the zoology of the voyage of HMS Beagle, focusing on fossil mammalia.',
            'A compilation of passages from Darwin’s writings, stating his theories and thoughts on evolution and natural selection in his own words.'
        ],
        "sentences_json": 'https://drive.google.com/file/d/1bgvrTsDUqWYmoUqVeORNVP3Y2CkL7Kv7/view?usp=share_link',
        "segments_json": 'https://drive.google.com/file/d/1Yh2ey5uxoTSEnA9_L5EXrXLWWo1PnzvN/view?usp=share_link',
        "paragraphs_json": 'https://drive.google.com/file/d/1607oWxOQli9BPL-anOmJ3NuSZgfT5hP6/view?usp=share_link'
    },
    {
        "id": "Friedrich Nietzsche",
        "category": ["Philosophy", "Religion", "German", "German Literature", "Sociology", "Morality", "Professor", "Nihilism", "Geneology"],
        "cap_first": "Nietzsche",
        "cluster": "Friedrich_Nietzsche",
        "first": "friedrich",
        "last": "nietzsche",
        "image": "nietzschepfp.png",
        "description": "Philosopher & Cultural Critic (1844-1900) | Exploring ethics, metaphysics, epistemology, nihilism, & aesthetics | Champion of individualism & self-overcoming | \"God is dead, God remains dead, and we have killed him.\"",
        "books": ['Thus Spake Zarathustra  A Book for All and None',
            'Beyond Good and Evil',
            'Homer and Classical Philology',
            'We Philologists Complete Works of Friedrich Nietzsche, Volume 8',
            'The Antichrist',
            'The Case of Wagner, Nietzsche Contra Wagner, and Selected Aphorisms.',
            'On the Future of our Educational Institutions',
            'Human, All-Too-Human  A Book for Free Spirits, Part 2',
            'Thoughts Out of Season, Part II',
            'The Dawn of Day',
            'The Birth of Tragedy; or, Hellenism and Pessimism',
            'Early Greek Philosophy & Other Essays Collected Works, Volume Two',
            'Thoughts out of Season, Part I David Strauss, the Confessor and the Writer - Richard Wagner in Bayreuth.',
            'Human, All-Too-Human  A Book for Free Spirits, Part 1 Complete Works, Volume Six',
            'The Joyful Wisdom ( La Gaya Scienza ) Complete Works, Volume Ten',
            'Ecce Homo Complete Works, Volume Seventeen',
            'The Twilight of the Idols; or, How to Philosophize with the Hammer. The Antichrist Complete Works, Volume Sixteen',
            'The Genealogy of Morals The Complete Works, Volume Thirteen, edited by Dr. Oscar Levy.',
            'The Will to Power  An Attempted Transvaluation of All Values. Book I and II',
            'The Will to Power  An Attempted Transvaluation of All Values. Book III and IV'
        ],
        "book_descriptions": [
            "A philosophical novel that explores the journey of the prophet Zarathustra as he shares his wisdom with the masses, offering insights on morality, life, and the will to power.",
            "A profound work that challenges traditional notions of morality, proposing a reinterpretation of moral values free from religious preconceptions.",
            "An exploration into classical philology and its significance, with a particular focus on Homer’s epic poems and their influence on Western education and literature.",
            "Volume 8 presents a comprehensive look at Nietzsche's thoughts on philology, exploring its development, its practitioners, and its impact on society and education.",
            "A critical exploration of Christianity, wherein Nietzsche argues that Christian values undermine human creativity and suppress humanity’s natural instincts.",
            "A compilation exploring Nietzsche's critical views on Richard Wagner, his music, and his cultural influence, complemented by selected aphorisms.",
            "A critique and exploration of the educational institutions of Nietzsche's time, delving into their goals, methods, and effectiveness.",
            "Part 2 of a work for free spirits, delving into a range of topics and offering Nietzsche's insights into human nature, morality, and society.",
            "Part II presents a collection of essays that reflect Nietzsche’s bold and unconventional thinking, offering critique on culture, society, and individuals of his time.",
            "A philosophical work that explores various themes including morality, religion, and culture, providing insight into human nature and society.",
            "A compelling examination of the origins and development of tragedy as an art form, juxtaposed with the pessimism pervading Greek culture.",
            "Volume Two contains essays exploring early Greek philosophy and its impact on Western thought and society.",
            "Part I contains essays critiquing David Strauss and Richard Wagner, offering insight into their works and influence on society and culture.",
            "Part 1 explores various philosophical themes, offering Nietzsche’s perspectives on humanity, society, and morality.",
            "Volume Ten delves into the joyful wisdom, exploring themes of life, death, and the pursuit of happiness and knowledge.",
            "Volume Seventeen, Nietzsche's autobiographical work, offers insight into his life, works, and philosophical development.",
            "Volume Sixteen discusses how to philosophize with a hammer, providing a critical examination of idols of his time alongside his work, The Antichrist.",
            "Volume Thirteen examines the genealogy of morals, presenting Nietzsche’s critique of moral values and exploration of their origins.",
            "Book I and II attempt a reevaluation of all values, exploring themes of will, power, and their role in shaping society and individuals.",
            "Book III and IV continue the exploration of the will to power and the attempted transvaluation of all values, providing further insight into Nietzsche’s philosophy."
        ],
        "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Friedrich%20Nietzsche/nietzsche_books_sentences.json',
        "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Friedrich%20Nietzsche/nietzsche_books_segments.json',
        "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Friedrich%20Nietzsche/nietzsche_books_paragraphs.json',
    },
    {
        "id": "Greco Romans",
        "category": ["Philosophy", "Empires", "History", "Politics", "Religion", "Art", "Epic", "Mythology", "Ancient Greece", "Ancient Rome"],
        "cluster": "Greco_Sections",
        "image": "greckopfp.png",
        "description": "The collected works of: Aeschylus | Aestop | Apollonius | Apuleius | Aristophanes | Aristotle | Augustus | Epictetus | Epicurus | Euripides | Galen | Herodotus | Hippocrates| Homer | Julius Caesar | Licretius | Marcus Aurelius | Ovid | Plato | Plotinus | Plutarch | Porphyry | Quintus | Sophocles | Tacitus | Thucydides | Virgil",
    },
    {
        "id": "Henry David Thoreau",
        "category": ["Naturalism", "Philosophy", "Civil Rights", "Literature", "Nature", "Happiness", "American History", "Forest", "Wilderness", "Self-Reliance"],
        "cap_first": "Thoreau",
        "cluster": "Henry_David_Thoreau",
        "first": "henry",
        "last": "thoreau",
        "image": "thoreaupfp.png",
        "description": "Philosopher & Naturalist (1817-1862) | Wrote on the importance of nature, self-reliance, and simplicity | Lived in a cabin in the woods for two years | I show how we all live shares little resemblance with how we ought to live  | \"I went to the woods because I wished to live deliberately, to front only the essential facts of life, and see if I could not learn what it had to teach, and not, when I came to die, discover that I had not lived.\"",
        "books": [
            "A Plea for Captain John Brown Read to the citizens of Concord, Massachusetts on Sunday evening, October thirtieth, eighteen fifty-nine",
            "A Week on the Concord and Merrimack Rivers",
            "A Yankee in Canada with Antislavery and reform papers",
            "Canoeing in the wilderness",
            "Cape Cod",
            "Excursions, and Poems The Writings of Henry David Thoreau, Volume 05 (of 20)",
            "Excursions",
            "Familiar Letters The Writings of Henry David Thoreau, Volume 06 (of 20)",
            "Journal 01, 1837-1846 The Writings of Henry David Thoreau, Volume 07 (of 20)",
            "Journal 02, 1850-September 15, 1851 The Writings of Henry David Thoreau, Volume 08 (of 20)",
            "On the Duty of Civil Disobedience",
            "Paradise (to be) Regained",
            "Poems of Nature",
            "The Maine Woods The Writings of Henry David Thoreau, Volume 03 (of 20)",
            "The Service",
            "Walking",
            "Wild Apples",
        ],
        "book_descriptions": [
            "A compelling speech that advocates for the moral righteousness of the actions of John Brown, a radical abolitionist who led an armed insurrection against slavery.",
            "A detailed narrative of Thoreau's boat trip with his brother John along the Concord and Merrimack Rivers, musing on philosophy, history, and the essence of life.",
            "A travel narrative depicting Thoreau’s experiences in Canada, complemented by a collection of his essays on antislavery and reform.",
            "A vivid recount of Thoreau's canoeing adventures in the wilderness, illuminating his profound connection with nature and his adept skills in navigating the wild.",
            "A richly detailed account of Thoreau’s explorations on Cape Cod, providing a unique perspective on the landscape, people, and essence of the place.",
            "Volume 05 includes Thoreau’s excursions and poems, offering a diverse collection of his writings that reflect his love for nature and his philosophical insights.",
            "A collection of Thoreau’s essays from his travels, offering observations and reflections on nature, humanity, and life.",
            "Volume 06 provides a collection of Thoreau's letters, giving readers insight into his personal life, relationships, and thoughts.",
            "Volume 07 presents Thoreau’s journal entries from 1837 to 1846, showcasing his daily reflections, observations, and deep connection with nature and life.",
            "Volume 08 continues with Thoreau’s journal entries from 1850 to 1851, offering a closer look at his thoughts, observations, and his profound love for nature.",
            "An essay that argues for individuals to resist governmental civil injustice, highlighting the moral duty to oppose laws that conflict with individual conscience.",
            "An essay expressing optimism for humanity’s future, advocating for a return to harmony with nature for the attainment of paradise.",
            "A collection of Thoreau’s poems reflecting his profound love for nature and his keen observations of the natural world.",
            "Volume 03 explores Thoreau’s journeys in the Maine Woods, offering vivid descriptions and reflections on the wilderness and its influence on him.",
            "An essay exploring the concept of service and its relation to a higher moral law, emphasizing inner integrity over external observances.",
            "An essay highlighting the importance of walking as a means of connecting with nature, promoting inner peace, and fostering physical and mental well-being.",
            "An essay exploring the history, beauty, and symbolism of wild apples, reflecting Thoreau’s deep love for nature and his keen observational skills."
        ],
    },
    { "id": "The Occult",
        "category": ["Mysticism", "Psychology", "Religion", "Truth", "Seeking", "Spirituality", "Discovery", "Occult", "Esotericism", "Secrets", "Enlightenment", "Idealism"],
        "cluster": "The_Occult",
        "image": "occultpfp.png",
        "description": "Top 20 Books by Popularity, aggregated by Julie of globalgreyebooks.com: General Book of the Tarot | Black Pullet | Book of Forbidden Knowledge | Grand Grimoire with Great Clavicle of Solomon | The Simon Necronomicon | Book of Ceremonial Magic | Psychic Self Defense | Dogma et Rituel de la Haute Magie Part I | Dogma et Rituel de la Haute Magie Part II | Philosophy of Natural Magic | Goal of Life | Sixth and Seventh Books of Moses | The Chymical Wedding of Christian Rosenkreutz | Secret Teachings of All Ages | Symbolism of the Tarot | The Tarot of the Bohemians | Key of Solomon the King | Mathers Sacred Magic of Abramelin the Mage | Kybalion | Numbers Their Occult Power and Mystic Virtues",
        "books": [
            "Black Pullet",
            "Book of Ceremonial Magic",
            "Book of Forbidden Knowledge",
            "Dogma et Rituel de la Haute Magie Part I",
            "Dogma et Rituel de la Haute Magie Part II",
            "General Book of the Tarot",
            "Goal of Life",
            "Grand Grimoire with Great Clavicle of Solomon",
            "Key of Solomon the King",
            "Kybalion",
            "Mathers Sacred Magic of Abramelin the Mage",
            "Numbers Their Occult Power and Mystic Virtues",
            "Philosophy of Natural Magic",
            "Psychic Self Defense",
            "Secret Teachings of All Ages",
            "Sixth and Seventh Books of Moses",
            "Symbolism of the Tarot",
            "The Chymical Wedding of Christian Rosenkreutz",
            "The Simon Necronomicon",
            "The Tarot of the Bohemians",
        ],
        "book_descriptions": [
            "A mystical text allegedly inscribed by a mysterious figure, revealing the art of manipulating magical talismans for protection and magical knowledge.",
            "A comprehensive guide that explores the theories and practices of ceremonial magic, offering detailed insights into the arcane world of occult rituals and symbols.",
            "A collection of esoteric knowledge forbidden to the uninitiated, detailing various occult practices, charms, and divinations.",
            "Part I offers an in-depth exploration into the world of high magic, discussing theories, rituals, and the foundational principles that underlie magical practices.",
            "Continuing from Part I, Part II further delves into the ritual and practice of high magic, offering deeper insights and practical instructions for the aspiring magician.",
            "A detailed guide to understanding the Tarot, offering interpretative insights into the symbolism, meanings, and usage of Tarot cards in divination.",
            "An exploration of life's ultimate purpose, delving into mystical and esoteric theories to uncover the hidden path to enlightenment and self-realization.",
            "A powerful grimoire revealing the secrets of summoning and controlling powerful spirits, linked to the legendary King Solomon.",
            "A comprehensive manual detailing the key of Solomon, revealing the ancient secrets of invoking and commanding spirits and forces.",
            "A foundational text on hermetic philosophy, exploring the principles of mentalism, correspondence, and other hermetic laws.",
            "Explores the sacred magic of Abramelin the Mage, detailing the ritual processes and spiritual exercises for attaining higher knowledge and divine wisdom.",
            "A thorough examination of numbers and their esoteric significance, exploring their hidden meanings and the power they hold within the occult.",
            "A treatise on natural magic, exploring the philosophical principles that underlie the practice of magical arts and the connection with natural forces.",
            "A guide on how to defend oneself against psychic attacks, offering practical advice and techniques for psychic self-protection.",
            "A comprehensive exploration of the hidden teachings of various ages, delving into the mysteries of ancient symbols, rituals, and esoteric philosophies.",
            "Explores the magical and mystical significance of the Sixth and Seventh Books of Moses, offering insights into their teachings and practices.",
            "A deep dive into the symbolism within the Tarot, revealing the profound meanings embedded within the cards and their connections to various esoteric traditions.",
            "A mystical narrative describing the allegorical journey of Christian Rosenkreutz, exploring alchemical and spiritual enlightenment.",
            "Presents a collection of ancient Sumerian and Babylonian texts, offering insights into the lore, magick, and spirituality of the ancient Near East.",
            "A comprehensive guide to understanding and practicing Tarot, offering insights into card meanings, symbolism, and their use in divination and spiritual growth."
        ],
    },
    {
        "id": "Thoth",
        "category": ["Mysticism", "Hermeticism", "Thoth", "Esotericism"],
        "cap_first": "Hermes",
        "cluster": "Hermes_Segments",
        "first": "hermes",
        "last": "trismegistus",
        "image": "hermespfp.png",
        "description": "Ancient Egyptian God of Wisdom (6000-2000 BCE) | Divine scribe & god of wisdom | Inventor of writing, magic & the lunar calendar | Maintaining cosmic balance | Reincarnated in Greece as the Messenger of Gods (1600-300BCE) | Syncretic sage & author of Hermetic texts | Fusing Greek & Egyptian wisdom 'As above, so below; as within, so without.' | Embodying the secrets of the universe",
        "books": ['A Treatise on Initiations, or Asclepios',
            'Emerald Tablets of Thoth The Atlantian',
            'Fragments of the Book of Hermes to his Son Tatios',
            'Fragments of the Writings of Hermes to Ammon',
            'Fragments of the Writings of Hermes',
            'The Corpus Hermeticum I. Poemandres, the Shepherd of Men',
            'The Corpus Hermeticum II. To Asclepius',
            'The Corpus Hermeticum III. The Sacred Sermon',
            'The Corpus Hermeticum IV. The Cup or Monad',
            'The Corpus Hermeticum V. Though Unmanifest God Is Most Manifest',
            'The Corpus Hermeticum VI. In God Alone Is Good And Elsewhere Nowhere',
            'The Corpus Hermeticum VII. The Greatest Ill Among Men is Ignorance of God',
            'The Corpus Hermeticum VIII. That No One of Existing Things doth Perish, but Men in Error Speak of Their Changes as Destructions and as Deaths',
            'The Corpus Hermeticum IV. On Thought and Sense',
            'The Corpus Hermeticum X. The Key',
            'The Corpus Hermeticum XI. Mind Unto Hermes',
            'The Corpus Hermeticum XII. About The Common Mind',
            'The Corpus Hermeticum XIII. The Secret Sermon on the Mountain',
            'The Corpus Hermeticum XIV. A Letter of Thrice-Greatest Hermes to Asclepius',
            'The Corpus Hermeticum XV. The Definitions of Asclepius unto King Ammon',
            'The Corpus Hermeticum XVI. Of Asclepius to the King',
            'The The Corpus Hermeticum XVII. The Encomium of Kings',
            'The Definitions of Asclepios',
            'The Perfect Sermon, or The Asclepius',
            'The Virgin of The World'
        ],
        "book_descriptions": [
            "A comprehensive treatise discussing the ancient rituals and knowledge surrounding initiations, presented through a conversation between Hermes and Asclepios.",
            "A seminal text offering insights and wisdom from Thoth the Atlantean, revealing ancient secrets of alchemy, life, and the universe.",
            "A collection of fragments offering insights from the conversations of Hermes with his son, Tatios, discussing various metaphysical and philosophical issues.",
            "Pieces of Hermes' teachings to Ammon, discussing ancient wisdom, spirituality, and the cosmos.",
            "A collection of Hermes’ fragmented writings that explore various aspects of hermetic wisdom, spirituality, and understanding of the universe.",
            "The first book of The Corpus Hermeticum wherein Poemandres shares profound spiritual wisdom and insights into the nature of the universe and humanity.",
            "The second book of The Corpus Hermeticum, presenting teachings and wisdom conveyed to Asclepius about the divine and the nature of reality.",
            "The third book of The Corpus Hermeticum offers a sacred sermon, expounding on spiritual wisdom and divine truths.",
            "The fourth book discusses the concept of The Cup or Monad, exploring the themes of unity, wholeness, and the interconnection of all things.",
            "The fifth book explores the paradoxical nature of God, elucidating how the unmanifest God is most manifest in the cosmos.",
            "The sixth book highlights the idea that true goodness resides in God alone, while it is absent elsewhere.",
            "The seventh book emphasizes the ill of ignorance about God, advocating the importance of knowledge and understanding of the divine.",
            "The eighth book provides insights into the eternal nature of existence, debunking the human misconceptions regarding destruction and death.",
            "This book delves into the intricacies of thought and sense, discussing their roles and functions in understanding and perceiving reality.",
            "The tenth book, titled The Key, unlocks profound hermetic wisdom and insights, offering guidance and understanding to seekers of truth.",
            "The eleventh book presents a discourse of Mind unto Hermes, revealing deeper understanding and insights into the nature of the mind and reality.",
            "The twelfth book explores the concept of the Common Mind, discussing its attributes, functions, and significance in the grand scheme of existence.",
            "The thirteenth book provides a secret sermon on the mountain, offering spiritual enlightenment and wisdom from Hermes.",
            "The fourteenth book contains a letter from Thrice-Greatest Hermes to Asclepius, imparting profound hermetic wisdom and teachings.",
            "The fifteenth book presents the definitions given by Asclepius to King Ammon, elucidating various hermetic concepts and principles.",
            "The sixteenth book contains teachings of Asclepius to the King, offering royal wisdom and guidance on rulership and governance.",
            "The seventeenth book, The Encomium of Kings, extols the virtues and responsibilities of kingship, providing insights into righteous and wise rulership.",
            "This book offers the definitions of Asclepios, presenting a detailed exploration of hermetic wisdom and principles.",
            "Also known as The Asclepius, The Perfect Sermon imparts profound spiritual and philosophical teachings and insights from Hermes.",
            "The Virgin of The World unveils hermetic wisdom and teachings regarding the cosmos, divinity, and the intricate workings of the universe."
        ],
    },
    {
        "id": "Sigmund Freud",
        "category": ["Psychology", "Sexuality", "Psychoanalysis", "Freud", "Dreams", "Oedipus Complex", "Materialism"],
        "cluster": "Sigmund_Freud",
        "first": "sigmund",
        "last": "freud",
        "image": "freudpfp.png",
        "description": "Father of Psychoanalysis (1856-1939) | My theories on the unconscious mind revolutionized psychology | My work on dreams, the ego, and the id are still studied today | My ideas on the human psyche have influenced countless artists and writers | My work on sexuality and the Oedipus complex are still debated today | \"The mind is like an iceberg, it floats with one-seventh of its bulk above water.\"",
        "books": [
            "A General Introduction to Psychoanalysis",
            "An Outline of Psycho-Analysis",
            "Beyond the Pleasure Principle",
            "Civilization and Its Discontents",
            "Delusion and Dream   an Interpretation in the Light of Psychoanalysis of Gradiva",
            "Dream Psychology  Psychoanalysis for Beginners",
            "Group Psychology and The Analysis of The Ego",
            "Inhibitions Symptoms and Anxiety",
            "Jokes and Their Relation to The Unconscious",
            "Leonardo da Vinci  A Psychosexual Study of an Infantile Reminiscence",
            "Moses and Monotheism",
            "Psycho-Analysis and the War Neuroses",
            "Psychopathology of Everyday Life",
            "Reflections on War and Death",
            "Studies On Hysteria",
            "The Ego and the Id",
            "The Future of an Illusion",
            "The Interpretation of Dreams",
            "Three Contributions to the Theory of Sex",
            "Totem and Taboo Resemblances Between the Psychic Lives of Savages and Neurotics",
        ],
        "book_descriptions": [
            "A foundational text where Freud systematically outlines the key principles and concepts of psychoanalysis, providing a comprehensive overview for newcomers to the field.",
            "This work succinctly outlines Freud's theories on the mind and its workings, making psychoanalytic concepts more accessible to a broad audience.",
            "In this groundbreaking work, Freud explores the forces that drive humans beyond the pursuit of pleasure, introducing the concept of the death drive.",
            "Freud analyzes the conflict between individual instinctual desires and the repressive constraints of civilization, examining the resultant discontent.",
            "Freud provides a psychoanalytic interpretation of Wilhelm Jensen's novel Gradiva, exploring themes of fantasy, delusion, and dreams.",
            "An introductory text that offers a comprehensive and accessible exploration of dream psychology and psychoanalytic principles for beginners.",
            "Freud delves into the intricacies of group dynamics, exploring how individuals' egos and unconscious minds operate within a collective.",
            "This work explores the causes and implications of inhibitions, symptoms, and anxiety, shedding light on their psychoanalytic interpretations.",
            "Freud examines the psychological mechanisms behind humor, establishing a connection between jokes and the unconscious mind.",
            "This text presents a psychosexual analysis of Leonardo da Vinci, exploring his art and life through the lens of infantile reminiscence.",
            "Freud discusses the historical Moses, monotheism, and its influence on religion and civilization, presenting psychoanalytic insights.",
            "This book examines the psychological effects of World War I, offering a psychoanalytic perspective on war neuroses.",
            "Freud explores the psychological underpinnings of everyday errors, slips, and forgetfulness, revealing the role of the unconscious in these phenomena.",
            "In this contemplative work, Freud discusses the psychological and philosophical implications of war and death on the human psyche.",
            "This pioneering work delves into the study of hysteria, exploring case studies and discussing Freud’s collaborative work with Breuer.",
            "Freud delineates the functions and interactions of the ego, super ego, and id, offering insights into human behavior and mental processes.",
            "Freud examines religious illusion and its role in society, offering a critical perspective on religion’s future.",
            "This seminal work presents Freud's exploration and analysis of dreams, revealing their significance in understanding the unconscious mind.",
            "In this work, Freud discusses fundamental aspects of sexuality including sexual development and aberrations.",
            "Freud investigates the parallels between the psychic lives of savages and neurotics, exploring totemism and taboo in this context."
        ],
    },
];
export default AUTHOR_INFO;
// {
// "id": "Camille Flammarion",
// "category": ["Mysticism", "Astronomy"],
// "cap_first": "Flammarion",
// "cluster": "Flammarion_Segments",
// "first": "flammarion",
// "last": "camille",
// "image": "flammarionpfp.png",
// "description": "Astronomer & Popular Science Writer (1842-1925) | Author of the first popular astronomy book | Wrote over 100 books on science, philosophy, & history | Mixing Science and Mysticism, Psychology and Spirit, Astrology and Wisdom | \"The universe is a great machine, and the human mind is a great key.\"",
// "books": [
//             'Astronomical Myths',
//             'Astronomy for Amateurs',
//             'Death and its Mystery',
//             'Haunted Houses',
//             'Lumen',
//             'Mysterious Psychic Forces',
//             'Omega',
//             'Popular Astronomy; a general description of the heavens',
//             'The Marvels of the Heavens',
//             'The Unknown',
//             'Thunder and Lightning',
//             'Travels in the Air',
//             'Urania'
//         ],
// "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Camille%20Flammarion/flammarion_books_sentences.json',
// "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Camille%20Flammarion/flammarion_books_segments.json',
// "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Camille%20Flammarion/flammarion_books_paragraphs.json'
// },
//     {
//     "id": "Herman Melville",
//     "category": ["Literature"],
//     "cap_first": "Melville",
//     "cluster": "Melville_Segments",
//     "first": "herman",
//     "last": "melville",
//     "image": "melvillepfp.png",
//     "description": "Masterful Author & Storyteller (1819-1891) | Unraveling the depths of human experience | Redefined American literature with Moby-Dick | Explored life at sea & society's complexities | Wrote on culture, philosophy, and human nature | Bridged the realms of fiction and reality | \"It is better to fail in originality than to succeed in imitation.\"",
//     "books": ['Moby Dick  or  The Whale', 'Typee  A Romance of the South Seas', 'I and My Chimney', 'Omoo  Adventures in the South Seas', 'Redburn  His First Voyage Being the Sailor Boy Confessions and Reminiscences of the Son Of A Gentleman in the Merchant Navy', 'White Jacket  Or  The World on a Man of War', 'Bartleby  the Scrivener  A Story of Wall Street', 'Battle Pieces and Aspects of the War', 'John Marr and Other Poems', 'Mardi  and A Voyage Thither  Vol  I', 'Mardi  and A Voyage Thither  Vol  II', 'Israel Potter  His Fifty Years of Exile', 'The Piazza Tales', 'The Confidence Man  His Masquerade', 'Pierre  or The Ambiguities', 'The Apple Tree Table  and Other Sketches'],
//     "sentences_json": 'https://drive.google.com/file/d/1yGLCs5RC0d-IRqCjeT12wv9A86Z4YKs4/view?usp=share_link',
//     "segments_json": 'https://drive.google.com/file/d/1i4vZ0loKVTXkGF5BtXwnoSZawwz_57RY/view?usp=share_link',
//     "paragraphs_json": 'https://drive.google.com/file/d/16t4weUZU5XqYWFFyaD7YhrRwk92YqMBu/view?usp=share_link'
//     },
//     {
//     "id": "Jane Austen",
//     "category": ["Literature", "Romance"],
//     "cap_first": "Jane",
//     "cluster": "Jane_Segments",
//     "first": "jane",
//     "last": "austen",
//     "image": "austenpfp.png",
//     "description": "English Novelist of Manners (1775-1817) | Author of Pride & Prejudice, Sense & Sensibility, Emma, & Persuasion | Masterfully dissecting social norms with wit | \"I declare after all there is no enjoyment like reading! How much sooner one tires of anything than of a book!\"",
//     "books": [
//             'Emma',
//             'Lady Susan',
//             'Love and Friendship',
//             'Mansfield Park',
//             'Northanger Abbey',
//             'Persuasion',
//             'Pride and Prejudice',
//             'Sense and Sensibility',
//             'The Letters of Jane Austen'
//             ],
//     "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Jane%20Austen/jane_books_sentences.json',
//     "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Jane%20Austen/jane_books_segments.json',
//     "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Jane%20Austen/jane_books_paragraphs.json'
//     },
//     {
//     "id": "John Dewey",
//     "category": ["Psychology", "Philosophy"],
//     "cap_first": "Dewey",
//     "cluster": "Dewey_Segments",
//     "first": "john",
//     "last": "dewey",
//     "image": "deweypfp.png",
//     "description": "American Philosopher & Educator (1859-1952) | Educator, Founder of the Philosophical School of Pragmatism, Avid Supporter of the Arts | Author of Democracy & Education, Experience & Nature, & The Public and Its Problems | \"Education is not preparation for life; education is life itself.\"",
//     "books": [
//             'A Common Faith',
//             'Art As An Experience',
//             'China, Japan and the U.S.A.',
//             'Creative Intelligence; Essays in Pragmatic Attitude',
//             'Democracy and Education',
//             'Essays in Experimental Logic',
//             'Ethics',
//             'Experience And Nature',
//             'Freedom and Culture',
//             'German Philosophy and Politics',
//             'How we Think',
//             'Human Nature and Conduct',
//             'Leibnizs New Essays Concerning the Human Understanding',
//             'Letters from China and Japan',
//             'Logic The Theory Of Inquiry',
//             'Moral Principles in Education',
//             'Outlines of a Critical Theory of Ethics',
//             'Philosophy And Civilization',
//             'Psychology and Social Practice',
//             'Reconstruction in Philosophy',
//             'Schools of To-morrow',
//             'Studies in Logical Theory',
//             'The Child and the Curriculum',
//             'The Influence of Darwin on Philosophy, and other essays in contemporary thought',
//             'The School and Society'
//             ],
//     "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/John%20Dewey/dewey_books_sentences.json',
//     "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/John%20Dewey/dewey_books_segments.json',
//     "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/John%20Dewey/dewey_books_paragraphs.json'
//     },
// {
//     "id": "John Stuart Mill",
//     "category": ["Philosophy", "Politics", "Economics"],
//     "cap_first": "Mill",
//     "first": "john",
//     "last": "stuart mill",
//     "image": "millpfp.png",
//     "description" : "Philosopher, Economist, and Political Thinker (1806-1873) | My works on liberty, democracy, and utilitarianism have had a profound impact on political theory | I championed individual rights and the importance of social justice | Through my essays and treatises, I advocated for education and intellectual development as a means to improve society | A leading figure in the 19th century Enlightenment, I sought to reconcile reason and human emotion | \"The only purpose for which power can be rightfully exercised over any member of a civilized community, against his will, is to prevent harm to others. That is my fundamental principle of morality.\"",
//     "books": ["tbd"],
//     "sentences_json": "tbd",
//     "segments_json": "tbd",
//     "paragraphs_json": "tbd"
// },
//     {
//     "id": "Mary Shelley",
//     "category": ["Literature", "Romance"],
//     "cap_first": "Mary",
//     "cluster": "Mary_Segments",
//     "first": "mary",
//     "last": "shelley",
//     "image": "marypfp.png",
//     "description": "British Novelist (1797-1851) | Mother of Science Fiction | Author of Frankenstein | Delving into the human condition, the consequences of scientific progress, & pushing the boundaries of literature | Bold, groundbreaking storyteller | \"Beware; for I am fearless, and therefore powerful.\"",
//     "books": [  'Eminent literary and scientific men of Italy, Spain, and Portugal. Vol. 1',
//                 'Eminent literary and scientific men of Italy, Spain, and Portugal. Vol. 2',
//                 'Eminent literary and scientific men of Italy, Spain, and Portugal. Vol. 3',
//                 'Falkner; A Novel',
//                 'Frankenstein; Or, The Modern Prometheus',
//                 'History of a Six Weeks Tour',
//                 'Lives of the most eminent literary and scientific men of France, Vol. 1',
//                 'Lives of the most eminent literary and scientific men of France, Vol. 2',
//                 'Lodore Vol. 1',
//                 'Lodore Vol. 2',
//                 'Lodore Vol. 3',
//                 'Mathilda',
//                 'Notes to the Complete Poetical Work of Percy Bysshe Shelley',
//                 'Proserpine and Midas',
//                 'Tales and Stories',
//                 'The Fortunes of Perkin Warbeck',
//                 'The Heir of Mondolfo',
//                 'The Last Man',
//                 'Valperga Vol. 1',
//                 'Valperga Vol. 2',
//                 'Valperga Vol. 3'
//             ],
//     "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Mary%20Shelley/mary_books_sentences.json',
//     "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Mary%20Shelley/mary_books_segments.json',
//     "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/data/Mary%20Shelley/mary_books_paragraphs.json'
//     },
// {"id": "Niccolo Machiavelli",
// "category": ["Philosophy", "Politics"],
// "cap_first": "Machiavelli",
// "first": "niccolo",
// "last": "machiavelli",
// "image": "machiavellipfp.png",
// "description": "Political Philosopher & Diplomat (1469-1527) | Master of Realpolitik | Architect of \"The Prince\" | Advocate of power dynamics & political cunning | Igniting the pragmatic approach in statecraft, challenging morality and ethics. | \"It is better to be feared than loved, if you cannot be both.\"",
// "books": [ 'Discourses on the First Decade of Titus Livius',
//             'TBD'
//         ],
// "sentences_json": 'nothting',
// "segments_json": 'doublecheck gutenburg has everything.',
// "paragraphs_json": 'doublecheck gutenburg has everything.'
// },
//     {
//     "id": "Richard Wagner",
//     "category": ["Music", "Philosophy"],
//     "cap_first": "Wagner",
//     "cluster": "Wagner_Segments",
//     "first": "wagner",
//     "last": "richard",
//     "image": "wagnerpfp.png",
//     "description": "Renaissance Man & Composer (1813-1883) | Unveiling the creative process | Redefined the concept of music and opera | Influenced the modern theatrical form | Wrote on music, philosophy, and politics | Bridged the arts, sciences, and humanities | \"Music is the language of the heart without words.\"",
//     "books" : [
//                 'Collection of Richard Wagner Prose Works',
//                 'Correspondence of Wagner and Liszt, Volume 1',
//                 'Correspondence of Wagner and Liszt, Volume 2',
//                 'Jewishness in Music',
//                 'My Life Volume 1',
//                 'My Life Volume 2',
//                 'On Conducting; A Treatise on Style in the Execution of Classical Music',
//                 'Opera & Drama Volume II',
//                 'Opera and Drama Volume I',
//                 'Parsifal; A Drama by Wagner',
//                 'Siegfried and The Twilight of the Gods',
//                 'Stories from Wagner',
//                 'The Art-Work of the Future'
//             ],
//     "sentences_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Richard%20Wagner/wagner_books_sentences.json',
//     "segments_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Richard%20Wagner/wagner_books_segments.json',
//     "paragraphs_json": 'https://raw.githubusercontent.com/evanmcfarland/A-Statistical-Approach-to-Happiness/main/Richard%20Wagner/wagner_books_paragraphs.json'
//     },
// {
//     "id": "Frederick Douglass",
//     "category": ["Civil Rights"],
//     "cap_first": "Douglass",
//     "first": "frederick",
//     "last": "douglass",
//     "image": "douglasspfp.png",
//     "description": "🇺🇸 Abolitionist & Social Reformer (1818-1895) | Breaking the chains of slavery, advocating for human rights, & championing education | Symbol of resistance against racial segregation & beacon for equality | \"Without struggle, there is no progress.\"",
//     "books": [  "My Bondage and My Freedom",
//                 "TBD",
//             ],
//     "sentences_json": "Tbd",
//     "segments_json": "Tbd",
//     "paragraphs_json": "Tbd"
// },
//     {
//     "id": "Fyodor Dostoevsky",
//     "category": ["Literature", "Psychology"],
//     "cap_first": "Dostoyevsky",
//     "cluster": "Dostoyevsky_Segments",
//     "first": "fyodor",
//     "last": "dostoevsky",
//     "image": "dostoevskypfp.png",
//     "description": "Novelist & Philosopher (1821-1881) | Wrote on the human condition & the struggle between good & evil | From near-execution to literary greatness: Dostoevsky's fearless critiques of the Tsarist regime. | \"The degree of civilization in a society can be judged by entering its prisons.\"",
//     "books": [   
//                 'Notes from the Underground',
//                 'The Gambler',
//                 'Poor Folk',
//                 'Crime and Punishment',
//                 'The Idiot',
//                 'The Possessed',
//                 'The Grand Inquisitor',
//                 'The Brothers Karamazov',
//                 'White Nights and Other Stories',
//                 'The House of the Dead',
//                 "Stavrogin's Confession and The Plan of The Life of a Great Sinner"
//             ],
//     "sentences_json": 'https://drive.google.com/file/d/1aHSGMN1qfYM341g3784C1QOL6McNDNbn/view?usp=share_link',
//     "segments_json": 'https://drive.google.com/file/d/1xvqD_iA2A__Z8uPwRQXvNN5n7KbeBKKd/view?usp=share_link',
//     "paragraphs_json": 'https://drive.google.com/file/d/1IxFU0YXhyNC-8jjPzu5Y4mXVyzRTAdCc/view?usp=share_link'  
//     },
//     {
//     "id": "Shakespeare",
//     "category": ["Literature"],
//     "cap_first": "Shakespeare",
//     "cluster": "Shakespeare_Segments",
//     "first": "william",
//     "last": "shakespeare",
//     "image": "shakespearepfp.png",
//     "description": "Master of Intrigue & Timeless Tales (1564-1616) | The Bard of Avon, creator of iconic characters | Enriched the English language with countless phrases & expressions | Whose plays inspired generations of writers, composers, & filmmakers | Wove romance, tragedy, & comedy into unforgettable stories | Famed for depicting the human condition with unrivaled insight | \"To be or not to be, that's the question I'll forever be remembered for!\"",
//     "books" : [
//                     'Shakespeares Sonnets',
//                     'Venus and Adonis',
//                     'King Richard III',
//                     'The Comedy of Errors',
//                     'The Taming of the Shrew',
//                     'The Two Gentlemen of Verona',
//                     'Loves Labours Lost',
//                     'King John',
//                     'A Midsummer Nights Dream',
//                     'The Merchant of Venice',
//                     'The Merry Wives of Windsor',
//                     'As You Like It',
//                     'Alls Well That Ends Well',
//                     'Measure for Measure',
//                     'Cymbeline',
//                     'The Winters Tale',
//                     'The Tempest',
//                     'A Lovers Complaint',
//                     'The Rape of Lucrece',
//                     'King Richard II',
//                     'Romeo and Juliet',
//                     'Julius Caesar',
//                     'The Phoenix and the Turtle',
//                     'Macbeth',
//                     'Antony and Cleopatra',
//                     'Pericles  Prince of Tyre',
//                     'The Passionate Pilgrim',
//                     'Sonnets on Sundry Notes of Music',
//                     'Titus Andronicus',
//                     'King Henry IV  Part 1',
//                     'King Henry IV  Part 2',
//                     'King Henry V',
//                     'Hamlet',
//                     'Othello',
//                     'King Lear',
//                     'Coriolanus',
//                     'Timon of Athens',
//                     'King Henry VIII',
//                     'Twelfth Night',
//                     'Much Ado About Nothing'
//             ],
//     "sentences_json": 'https://drive.google.com/file/d/1PuKbxzoAmgU8iwMBl6AlYroIBWdNWoNX/view?usp=share_link',
//     "segments_json": 'https://drive.google.com/file/d/1fF17pqlYYZBFoGH3MwBDs_VlsQWhbZCW/view?usp=share_link',
//     "paragraphs_json": 'https://drive.google.com/file/d/1fF17pqlYYZBFoGH3MwBDs_VlsQWhbZCW/view?usp=share_link'
//     },
// {
// "id": "Rene Descartes",
// "category": ["Philosophy", "Mathematics"],
// "cap_first": "Rene",
// "first": "rene",
// "last": "descartes",
// "image": "descartespfp.png",
// "description": "Philosopher, Mathematician & Scientist (1596-1650) | Father of Modern Philosophy | Pioneer of \"Meditations on First Philosophy\" | Champion of methodic doubt & rationalism | Propelling humanity forward, one rational thought at a time. | \"Cogito, ergo sum\" (I think, therefore I am)",
// "books" : [
//                 'A Discourse on Method',
//                 'TBD'
//         ],
// "sentences_json": 'tbd',
// "segments_json": 'tbd',
// "paragraphs_json": 'tbd'
// }
//     {
//     "id": "Voltaire",
//     "category": ["Philosophy"],
//     "cap_first": "Voltaire",
//     "cluster": "Voltaire_Segments",
//     "first": "voltaire",
//     "last": "voltaire",
//     "image": "voltairepfp.png",
//     "description": "François-Marie Arouet: Acclaimed Satirist & Enlightenment Luminary (1694-1778) | As the Sage of Ferney, my wit and wisdom have left an indelible mark | I fought against tyranny with every breath | My passion for human rights inspired the French Revolution | Through satire, I challenged society's ills and shaped social reform | Seeded the philosophy of the Enlightenment, nurturing reason and intellectual curiosity | \"I may not agree with what you say, but I will defend to the death your right to say it - that is the legacy I leave behind!\"",
//     "books": [   
//                 'A Philosophical Dictionary, Volume 1',
//                 'A Philosophical Dictionary, Volume 2',
//                 'A Philosophical Dictionary, Volume 3',
//                 'A Philosophical Dictionary, Volume 4',
//                 'A Philosophical Dictionary, Volume 5',
//                 'A Philosophical Dictionary, Volume 6',
//                 'A Philosophical Dictionary, Volume 7',
//                 'A Philosophical Dictionary, Volume 8',
//                 'A Philosophical Dictionary, Volume 9',
//                 'A Philosophical Dictionary, Volume 10',
//                 'Candide',
//             'Letters on England',
//             'Micromegas',
//             'Socrates',
//             'Superstition In All Ages',
//             'The Fourth Book of Virgils Aeneid and the Ninth Book of Voltaires Henriad',
//             'The History of Peter the Great, Emperor of Russia',
//             'The Works of Voltaire, Vol. IV of XLIII.',
//             'Voltaires Philosophical Dictionary',
//             'Voltaires Romances, Complete in One Volume',
//             'Zadig'
//             ],
//     "sentences_json": 'https://drive.google.com/file/d/1q6K23Gkj9pGCUf1_QoHkepPMQJq7JYD1/view?usp=share_link',
//     "segments_json": 'https://drive.google.com/file/d/11cPpdwHeaef09qSueQLGnwdhHg05dFF0/view?usp=share_link',
//     "paragraphs_json": 'https://drive.google.com/file/d/1ZkBw31QVBPNapLnHlwyHAfn_GH18xlYd/view?usp=share_link'
//     },

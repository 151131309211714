import { Reader } from "@/Reader";
import { ReaderProvider } from "@/Reader/lib/providers/ReaderProvider";
import React from "react";
var BookModal = function (_a) {
    var _b = _a.bookUrl, bookUrl = _b === void 0 ? "test.epub" : _b;
    return (React.createElement(ReaderProvider, null,
        React.createElement("div", { className: "relative w-full p-2" },
            React.createElement("div", { className: "max-w-7xl m-auto grid grid-cols-1 gap-4" },
                React.createElement(Reader, { bookUrl: bookUrl })))));
};
export default BookModal;

import React, { useState, useContext } from 'react';
import '../../styles/MessageCard/MessageCard.css';
import MessageContext from '../../contexts/MessageContext';
import UserProfile from './UserProfile';
import DetailsHeader from './DetailsHeader';
import CardDetailsFront from './CardDetailsFront';
import CardDetailsBack from './CardDetailsBack';
var MessageCard = function (_a) {
    var isShared = _a.isShared, AuthorId = _a.AuthorId;
    var _b = useState(false), cardFlipped = _b[0], setCardFlipped = _b[1];
    var _c = useState('output'), messageType = _c[0], setMessageType = _c[1];
    var context = useContext(MessageContext);
    var messageData = (context === null || context === void 0 ? void 0 : context.message) || { user_query: 'Hello', message: 'Nyc Job Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam aliquid nostrum earum enim ipsum rem, error iure optio placeat! Molestias. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam aliquid nostrum earum enim ipsum rem, error iure optio placeat! Molestias. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam aliquid nostrum earum enim ipsum rem, error iure optio placeat! Molestias. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam aliquid nostrum earum enim ipsum rem, error iure optio placeat! Molestias.' };
    var currentAuthorId = AuthorId || (context === null || context === void 0 ? void 0 : context.currentAuthorId);
    var handleClick = function () {
        setCardFlipped(!cardFlipped);
    };
    var UpdateMessageType = function (messageType) {
        setMessageType(messageType);
    };
    if (!currentAuthorId || !messageData)
        return null;
    return (React.createElement("div", { className: isShared ? "mainMessageCard sharedCard" : "mainMessageCard" },
        React.createElement("div", { className: "innerMessageCard" },
            React.createElement(UserProfile, { currentAuthorId: currentAuthorId || AuthorId, InputMessage: messageData.user_query }),
            React.createElement("div", { className: "messageCardDetails" },
                React.createElement("div", { className: "innerMessageCardDetails" },
                    React.createElement(DetailsHeader, { onFlip: handleClick, onMessageTypeUpdate: UpdateMessageType, messageType: messageType }),
                    React.createElement("div", { className: "messageMainCradDetails" },
                        React.createElement("div", { className: cardFlipped ? "cardFetailsFilpper flip" : "cardFetailsFilpper" },
                            React.createElement(CardDetailsFront, { messageData: messageData, messageType: messageType }),
                            React.createElement(CardDetailsBack, { currentAuthorId: currentAuthorId }))))))));
};
export default MessageCard;

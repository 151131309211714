var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useContext } from 'react';
import './cardCreationpanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SearchedCards from './SearchedCards';
import PreviewCard from './PreviewCard';
import SelectedCard from './SelectedCard';
import MessageContext from '@/contexts/MessageContext';
import SkeltonLoading from './SkeltonLoading';
import BookmarkedSourceCards from './BookmarkedSourceCards';
var CardCreationPanel = function (_a) {
    var currentAuthorId = _a.currentAuthorId;
    var _b = useState([]), selectedSourceCards = _b[0], setSelectedSourceCards = _b[1];
    var messageContext = useContext(MessageContext);
    var _c = useState(true), isOpened = _c[0], setIsOpened = _c[1];
    var SelectSourceCard = function (newItem) {
        var isSourceCardSelected = selectedSourceCards.find(function (item) { return (item === null || item === void 0 ? void 0 : item.title) === newItem.title; });
        !isSourceCardSelected && setSelectedSourceCards(function (prev) { return (__spreadArray(__spreadArray([], prev, true), [newItem], false)); });
    };
    var RemoveSourceCard = function (newItem) {
        var filteredCards = selectedSourceCards.filter(function (item) { return item.title !== newItem.title; });
        setSelectedSourceCards(filteredCards);
    };
    return (React.createElement(React.Fragment, null,
        (selectedSourceCards === null || selectedSourceCards === void 0 ? void 0 : selectedSourceCards.length) ? React.createElement(PreviewCard, { selectedSourceCards: selectedSourceCards, setSelectedSourceCards: setSelectedSourceCards }) : null,
        React.createElement("div", { className: 'mainCardCreationPanel_Container' },
            React.createElement("div", { className: "innerCardCreationPanel" },
                React.createElement("div", { className: "cardCreationPanelHeader" },
                    React.createElement("div", { className: "innerHeaderContainer" },
                        React.createElement("h2", null, "Create Your Own Card"),
                        React.createElement("div", { className: "innerHeaderctaBtns" },
                            React.createElement("button", { className: 'dropDown_btn', onClick: function () { return setIsOpened(!isOpened); } },
                                React.createElement(FontAwesomeIcon, { icon: isOpened ? faChevronUp : faChevronDown, size: 'sm' }))))),
                React.createElement("div", { className: isOpened ? "cardCreationPanel_InnerContainer active" : "cardCreationPanel_InnerContainer" },
                    React.createElement("div", { className: "cardCreation_selectedCards" },
                        React.createElement("div", { className: "header_selected_cards" },
                            React.createElement("h2", null, "Selecetd Cards")),
                        React.createElement("div", { className: "inner_body_selecetd_cards" }, (messageContext === null || messageContext === void 0 ? void 0 : messageContext.isLoading) ? [1, 2, 3].map(function (item) {
                            return (React.createElement(SkeltonLoading, { key: item }));
                        }) : React.createElement(React.Fragment, null, selectedSourceCards.map(function (item) {
                            return (React.createElement(SelectedCard, { item: item, RemoveSourceCard: RemoveSourceCard }));
                        })))),
                    React.createElement("div", { className: "cardCreation_searchedCards" },
                        React.createElement("div", { className: "header_searched_cards" },
                            React.createElement("h2", null, "Searched Results")),
                        React.createElement("div", { className: "innerSearchedCardsContainer" }, (messageContext === null || messageContext === void 0 ? void 0 : messageContext.isLoading) ? [1, 2, 3].map(function (item) {
                            return (React.createElement(SkeltonLoading, { key: item, isSearched: true }));
                        }) : React.createElement(React.Fragment, null, messageContext === null || messageContext === void 0 ? void 0 : messageContext.sourceCards.map(function (item, idx) {
                            return (React.createElement(SearchedCards, { item: item, key: idx, SelectSourceCard: SelectSourceCard }));
                        }))))))),
        React.createElement(BookmarkedSourceCards, { SelectSourceCard: SelectSourceCard })));
};
export default CardCreationPanel;
